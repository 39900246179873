import Chip from '@/common/Chip'

import React, { useRef, useState } from 'react'
import { FaCamera, FaPlus, FaSearch, FaTimes, FaVideo } from 'react-icons/fa'
import { FaX } from 'react-icons/fa6'
import { v4 as uuidv4 } from 'uuid'

import TextInput from '@/common/TextInput'
import Button from '@/common/button/Button'
import FileUpload from '../FileUpload'
import { ReactComponent as CloseBtn } from '@/assets/network/CloseBtn.svg'

import { POSTS } from '@/constants/querryKeys'
import CircularProgress from '@/common/spinners/CircularProgress'
import { imagesUrl } from '@/assets/images/imageUrls'
import { useQueryClient } from 'react-query'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import {
  useCreatePost,
  useCreatePostMedia,
  useDeletePost,
  useGetTimelineMentions,
} from '@/store/networkStore'
import { FileToUpload } from '../utils/types'
import Typography from '@/common/Typography'

import clsx from 'clsx'
import { PutFilesToStore } from '@/apis/profileApis'
import { PostMediaReqDTO } from '@/apis/networkApis'
import {
  formatStoreResponseForPost,
  handleFilesWithVideo,
} from '@/helpers/handleFilesWithVideo'

interface Props {
  toggleModal: () => void
}
interface SelectionClone {
  [key: string]: any
}

type TagObj = {
  name: string
  id: number
  userType: string
  type?: string
  img?: string
}

const insertTextAtCaretPosition = (
  item: TagObj,
  prevSelection: SelectionClone
) => {
  const textToInsert = item.name

  const range = new Range()
  if (prevSelection) {
    let element = document.createElement('span') as HTMLSpanElement

    if (textToInsert) {
      element.setAttribute(
        'class',
        `text-primary ${textToInsert.replace(/[\s-]+/g, '')} atTags`
      )
      element.innerHTML = ` @${textToInsert}`
      element.setAttribute('contenteditable', 'false')
      element.setAttribute('data-user-id', String(item.id))
      element.setAttribute('data-user-type', item.type || '')
    }
    range.setStart(prevSelection.baseNode, prevSelection.baseOffset)
    range.setEnd(prevSelection.baseNode, prevSelection.focusOffset)

    range.insertNode(element)

    range.collapse(true)
    const newSelection = window.getSelection()
    if (newSelection) {
      newSelection.removeAllRanges()
      newSelection.addRange(range)
    }
  }
}
const insertAtSign = (prevSelection: SelectionClone) => {
  const range = new Range()

  if (prevSelection) {
    const textNode = document.createTextNode('@')

    range.setStart(prevSelection.baseNode, prevSelection.baseOffset)
    range.setEnd(prevSelection.baseNode, prevSelection.focusOffset)

    range.insertNode(textNode)

    range.collapse(true)
    const newSelection = window.getSelection()
    if (newSelection) {
      newSelection.removeAllRanges()
      newSelection.addRange(range)
    }
  }
}

interface AtListProps {
  onClose: () => void
  onClickItem: (item: TagObj) => void
}

const AtList = ({ onClose, onClickItem }: AtListProps) => {
  const [search, setsearch] = useState<string>('')

  //get post mentions
  const { data, isLoading, isError } = useGetTimelineMentions({ query: search })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '@') return
    setsearch(e.target.value)
  }

  return (
    <div className='absolute top-[100%] left-0 bg-[white] shadow-md rounded-md w-[220px] h-[30vh] overflow-y-auto z-10 border-none p-2 pt-4'>
      <FaX
        className='absolute right-1 top-1 cursor-pointer'
        onClick={onClose}
        size={12}
      />
      <div className='flex flex-col align-start gap-3 mt-1'>
        <TextInput
          placeholder='Search'
          name='search'
          value={search}
          onChange={handleChange}
          startIcon={<FaSearch />}
          className='w-full border-none'
          autoFocus
        />
        {isLoading && <CircularProgress color='black' />}
        {!isError &&
          !isLoading &&
          Array.isArray(data?.data?.data) &&
          data?.data?.data?.map((data: TagObj, i: number) => (
            <div
              // key={data.id}
              key={i}
              className='flex items-center justify-start gap-1'
            >
              <img
                alt='uploaded_img'
                className={clsx(
                  'w-[25px] h-[25px] rounded-full object-center object-cover',
                  { 'rounded-md': data.type !== 'user' }
                )}
                src={
                  data?.img || data.type === 'user'
                    ? imagesUrl.blanckProfileImg
                    : imagesUrl.blanckCompImg
                }
              />

              <small
                className='cursor-pointer'
                onClick={() => onClickItem(data)}
              >
                {data?.name}
              </small>
            </div>
          ))}
      </div>
    </div>
  )
}

function ModalContainer({ toggleModal }: Props) {
  const prevSelection = useRef<SelectionClone>()

  const [isUploading, setisUploading] = useState(false)
  const editableRef = useRef<HTMLDivElement | null>(null)
  const queryClient = useQueryClient()
  const [openAtList, setopenAtList] = React.useState<boolean>(false)
  const [imgs, setimgs] = useState<JSX.Element[]>([])
  const [filesToUpload, setfilesToUpload] = useState<FileToUpload[]>([])
  const [tags, settags] = useState<Array<TagObj>>([])

  // Convert the Set to an array
  const tagsArray = Array.from(tags)

  const createImg = (file: File, src: string) => {
    const el = React.createElement
    const id = uuidv4()
    const deleteImgIcon = el(
      'span',
      {
        className: 'absolute top-2 right-2 cursor-pointer',

        onClick: () => {
          setimgs((prev) => prev.filter((prev) => prev.props.id !== id))
          setfilesToUpload((prev) => prev.filter((prev) => prev.id !== id))
        },
      },
      <CloseBtn />
    )

    const img = el('img', {
      className: 'w-full h-full rounded-md object-center object-cover',
      alt: 'img to upload',
      src,
    })
    const imgCont = el(
      'div',
      {
        className: 'relative rounded-md h-[100px]  w-[100%] sm:w-[212px] ',
        id,
      },
      deleteImgIcon,
      img
    )

    setimgs((prev) => [...prev, imgCont])
    setfilesToUpload((prev) => [...prev, { file, id }])
  }
  const createVideo = (file: File, src: string) => {
    const id = uuidv4()

    const vidCont = (
      <div
        id={id}
        className={`relative bg-gray-900 rounded-md h-[100px]  w-[100%] sm:w-[212px]`}
      >
        <span
          className='absolute top-1 right-1 cursor-pointer z-20'
          onClick={() => {
            setimgs((prev) => prev.filter((pre) => pre.props.id !== id))
            setfilesToUpload((prev) => prev.filter((prev) => prev.id !== id))
          }}
        >
          <CloseBtn />
        </span>

        <video
          controls
          disablePictureInPicture
          controlsList='nodownload nofullscreen noremoteplayback'
          muted
          className='m-auto w-full rounded-md h-full object-center object-cover'
          src={src}
          id='post-video'
        />
      </div>
    )

    setimgs((prev) => [...prev, vidCont])
    setfilesToUpload((prev) => [...prev, { file, id }])
  }
  const clonePrevSelection = () => {
    const sel = window.getSelection() as SelectionClone

    if (sel) {
      const clone: SelectionClone = {}
      for (let prop in sel) {
        clone[prop] = sel[prop] as SelectionClone
      }
      prevSelection.current = clone
    }
  }

  const handleKeyDown = (e: any) => {
    const key = e.key

    if (key === '@') setopenAtList(true)
    clonePrevSelection()
  }

  const onClickAtItem = (item: TagObj) => {
    insertTextAtCaretPosition(item, prevSelection.current as Selection)

    addTags()
    setopenAtList(false)
  }

  const handleRemoveTag = (tag: string) => {
    const tags: NodeListOf<HTMLSpanElement> =
      document.querySelectorAll(`.${tag.replace(/[\s-]+/g, '')}`) || []

    tags.forEach((t) => {
      t.remove()
    })
    addTags()
  }

  const addTags = () => {
    const inputArea: HTMLElement | null = document.getElementById('inputArea')
    const tags: NodeListOf<HTMLSpanElement> | never[] =
      inputArea?.querySelectorAll('.atTags') || []

    const tagObjects: TagObj[] = []

    tags.forEach(function (t) {
      const userId = t.getAttribute('data-user-id')
      const userType = t.getAttribute('data-user-type')
      const tagObj = {
        name: t.innerText.replace('@', ''),
        id: Number(userId),
        userType,
      }

      // Check if an object with the same "name" property already exists in the array
      const exists = tagObjects.some(
        (existingObj) => existingObj.name.trim() === tagObj.name.trim()
      )

      if (!exists) {
        tagObjects.push(tagObj as TagObj)
      }
    })

    settags(tagObjects)
  }

  const handleChange = (e: any) => {
    addTags()
  }

  const onFileSelelcted = (files: File | File[]) => {
    if (Array.isArray(files)) {
      files.forEach((file) => {
        const fileUrl = URL.createObjectURL(file)
        if (file.type === 'video/mp4') {
          createVideo(file, fileUrl)
        } else {
          createImg(file, fileUrl)
        }
      })
    }
  }
  const photoUpload = (
    <Button className='border' startIcon={<FaCamera />} size='xs' color='white'>
      Photo
    </Button>
  )
  const videoUpload = (
    <Button className='border' startIcon={<FaVideo />} size='xs' color='white'>
      Video
    </Button>
  )

  //create post

  const { mutateAsync: createPost, isLoading: createPostLoading } =
    useCreatePost({
      closeModal: () => toggleModal(),
      filesToUpload,
    })

  // create post media
  const { mutateAsync: createPostMedia, isLoading: isCreateMediaLoading } =
    useCreatePostMedia()

  //delete post
  const { mutateAsync: deletePost } = useDeletePost()

  const handdleCreatePost = async () => {
    setisUploading(true)

    const { error, response } = await withAsync(() => {
      const data = {
        content: editableRef?.current?.innerHTML as string,
        mentioned_users: tags
          .filter((tag) => tag.userType === 'user')
          .map((user) => user.id) as number[],
        mentioned_startups: tags
          .filter((tag) => tag.userType === 'startup')
          .map((user) => user.id) as number[],
        mentioned_funds: tags
          .filter((tag) => tag.userType === 'fund')
          .map((user) => user.id) as number[],
        mentioned_groups: tags
          .filter((tag) => tag.userType === 'syndicate')
          .map((user) => user.id) as number[],
      }

      return createPost(data)
    })

    if (error) {
      setisUploading(false)
      return errorHandler(error)
    }
    if (response) {
      if (filesToUpload?.length) {
        let res: any[]
        try {
          res = await PutFilesToStore({
            files: await handleFilesWithVideo(
              filesToUpload.map((file) => file.file)
            ),
            module_name: 'networks',
          })
        } catch (error) {
          setisUploading(false)
          return errorHandler(error)
        }

        const formattedResponse = res.filter(
          (f) => !f.url.includes('_thumbnail')
        )

        const files = formattedResponse.map((f) => {
          const fileData = formatStoreResponseForPost(
            res,
            response.data.data.id,
            f.url
          )
          return createPostMedia(fileData as PostMediaReqDTO)
        })

        // Await the resolution of all post media creation promises
        const { error: uploadErr, response: uploadResponse } = await withAsync(
          () => {
            return Promise.all(files)
          }
        )

        if (uploadErr) {
          await deletePost({ id: response.data.data.id })
          errorHandler(uploadErr)
          return
        }

        if (uploadResponse?.length) {
          queryClient.invalidateQueries([POSTS])

          toggleModal()
        }
      } else {
        queryClient.invalidateQueries([POSTS])
        toggleModal()
      }
    }
  }

  return (
    <div className='flex flex-col align-start w-full p-[24px] gap-2 '>
      <Typography heading='sm'>Post on Timeline</Typography>

      <div className='flex w-full flex-col align-start gap-2 rounded-md border border-gray-200 p-2 h-[40vh] overflow-y-auto'>
        <div className='relative'>
          <div
            className='h-auto min-h-[60px] w-full focus:outline-transparent'
            ref={editableRef}
            onInput={handleChange}
            contentEditable={openAtList ? 'false' : 'true'}
            onKeyDown={handleKeyDown}
            id='inputArea'
            data-placeholder='Start typing...'
            onMouseDown={() => clonePrevSelection()}
          />
          <div className={clsx({ block: openAtList, hidden: !openAtList })}>
            <AtList
              onClose={() => {
                insertAtSign(prevSelection.current as Selection)
                setopenAtList(false)
              }}
              onClickItem={(item: TagObj) => onClickAtItem(item)}
            />
          </div>
        </div>

        <div
          id='gridParent'
          className='grid grid-cols-2 gap-1 sm:grid-cols-2 lg:grid-cols-3'
        >
          {imgs.map((img, i) => (
            <React.Fragment key={uuidv4()}>{img}</React.Fragment>
          ))}
        </div>
      </div>

      <div className='w-full flex flex-col justify-between items-end lg:flex-row lg:items-center'>
        <div className='mt-2 flex flex-row gap-2 items-center flex-wrap'>
          <FileUpload
            btnElement={photoUpload}
            onFileSelected={onFileSelelcted}
            multiple
          />
          <FileUpload
            btnElement={videoUpload}
            onFileSelected={onFileSelelcted}
            type='video'
            multiple
          />

          {tagsArray.slice(0, 2).map((tag) => (
            <Chip key={tag.name} className='rounded-md bg-gray-200'>
              {tag.name}
              <FaTimes
                className='bg-[white] rounded cursor-pointer'
                onClick={() => handleRemoveTag(tag.name)}
              />
            </Chip>
          ))}

          {tags.length > 2 ? (
            <Chip className='rounded-md font-bold'>
              <FaPlus className='' />
              {tags.length - 2} more{' '}
              {tags.length - 2 === 1 ? 'person' : 'people'}
            </Chip>
          ) : null}
        </div>
        <Button
          className='w-[110px] px-5 font-bold'
          size='xs'
          onClick={handdleCreatePost}
          disabled={createPostLoading || isUploading}
          loading={createPostLoading || isCreateMediaLoading || isUploading}
        >
          Post
        </Button>
      </div>
    </div>
  )
}

export default ModalContainer
