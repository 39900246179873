import { ServerRes } from '@/apis/api.types'
import {
  CreateFile,
  DeleteAccessReq,
  DeleteDocument,
  DeleteUpdate,
  DownloadDocuments,
  GetAccessList,
  GetDataRoomData,
  GetDataRoomQueryDTO,
  GetDocument,
  GetDocumentViewers,
  GetFiles,
  GetFilesQueryDTO,
  GetFolders,
  GetItemQueryDTO,
  GetPublicDocument,
  GetUpdate,
  GetUpdates,
  GetUpdatesComments,
  GetUsersInvestors,
  GetUsersInvestorsQueryDTO,
  GrantAccessReq,
  PostUpdates,
  PostUpdatesComment,
  PostUpdatesMedia,
  SearchDocuments,
  ToggleUpdatesLike,
  UpdateAccessReq,
  UpddateDocument,
} from '@/apis/dataRoomApis'
import {
  CREATE_DOCUMENT,
  GET_ACCESS_LIST,
  GET_DATA_ROOM,
  GET_FILES,
  GET_FOLDERS,
  GET_UPDATES,
  GET_UPDATES_COMMENTS,
  SEARCH_DOCUMENTS,
  USERS_INVESTORS,
} from '@/apis/endpoints'
import { errorHandler } from '@/helpers/withAsync'
import { AxiosResponse } from 'axios'
import {
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

//get data room
export const useGetDataRoom = (data: {
  query?: string
  status?: 'founder' | 'member' | ''
}) => {
  return useInfiniteQuery(
    [GET_DATA_ROOM, { query: data?.query, status: data?.status }],
    ({ pageParam = 1 }) => GetDataRoomData({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//get folders
export const useGetFolders = (data: Omit<GetDataRoomQueryDTO, 'page'>) => {
  return useInfiniteQuery(
    [GET_FOLDERS, data.startup],
    ({ pageParam = 1 }) => GetFolders({ ...data, page: pageParam }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}
//get folders
export const useGetDocumentViewers = (data: Omit<GetItemQueryDTO, 'page'>) => {
  return useInfiniteQuery(
    [`${CREATE_DOCUMENT}${data.id}/viewers/`],
    ({ pageParam = 1 }) => GetDocumentViewers({ ...data, page: pageParam }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//get single document
export const useGetDocument = (data: GetItemQueryDTO) => {
  return useQuery([CREATE_DOCUMENT, data.id], () => GetDocument(data), {})
}
//get single public document
export const useGetPublicDocument = (data: GetItemQueryDTO) => {
  return useQuery([CREATE_DOCUMENT + data.name + '/' + data.id], () =>
    GetPublicDocument(data)
  )
}
//create folder & file
export const useCreateDocument = (queryToInvalidate: any[]) => {
  const qc = useQueryClient()
  return useMutation(CreateFile, {
    onSuccess() {
      return queryToInvalidate.forEach((query) => qc.invalidateQueries([query]))
    },
  })
}
//update document
export const useUpdateDocument = (queryToInvalidate: any[]) => {
  const qc = useQueryClient()
  return useMutation(UpddateDocument, {
    onSuccess() {
      return queryToInvalidate.forEach((query) => qc.invalidateQueries(query))
    },
  })
}
//delete document
export const useDeleteDocument = (queryToInvalidate: any[]) => {
  const qc = useQueryClient()
  return useMutation(DeleteDocument, {
    onSuccess() {
      return queryToInvalidate.forEach((query) => qc.invalidateQueries(query))
    },
  })
}

//download documents
export const useDownloadDocuments = () => {
  return useMutation(DownloadDocuments)
}

//get files
export const useGetFiles = (data: Omit<GetFilesQueryDTO, 'page'>) => {
  const key = data.startup ? 'startup' : 'fund'
  return useInfiniteQuery(
    [
      GET_FILES,
      data,
      // {
      //   sort_order: data?.sort_order,
      //   folder: data.folder,
      //   [key]: data[key as keyof typeof data],
      //   parent: data.parent,
      // },
    ],
    ({ pageParam = 1 }) => GetFiles({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//search documents
export const useSearchDocuments = (
  data: Omit<GetFilesQueryDTO, 'page'> & {
    cb?: (res: any) => void
    queryToSet: any[]
  }
) => {
  const qc = useQueryClient()
  const key = data.startup ? 'startup' : 'fund'
  return useInfiniteQuery(
    [
      SEARCH_DOCUMENTS,
      {
        query: data?.query,
        folder: data.folder,
        [key]: data[key as keyof typeof data],
      },
    ],
    ({ pageParam = 1 }) => SearchDocuments({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
      enabled: !!data.query,
      onSuccess: (res: any) => {
        data.queryToSet.forEach((query) =>
          qc.setQueryData(query, () => {
            return {
              ...res,
            }
          })
        )
        data?.cb && data?.cb(res)
      },
    }
  )
}

export const useSetDefaultQueryOnClearSearch = (
  data: { queryToInvalidate: any[]; invalidateData: Record<string, any> }[]
) => {
  const qc = useQueryClient() // Valid to use hook here

  const setDefaultQuery = () => {
    data.forEach((item) => {
      qc.setQueryData(item.queryToInvalidate, () => item.invalidateData)
    })
  }

  return { setDefaultQuery }
}

/***
 *
 * Updates tab
 */

//get updates
export const useGetUpdates = (data: Omit<GetFilesQueryDTO, 'page'>) => {
  return useInfiniteQuery(
    [
      GET_UPDATES,
      {
        query: data.query,
      },
    ],
    ({ pageParam = 1 }) => GetUpdates({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}
//get updates comments
export const useGetUpdatesComments = (data: Omit<GetItemQueryDTO, 'page'>) => {
  return useInfiniteQuery(
    [
      GET_UPDATES_COMMENTS,
      {
        update: data.id,
      },
    ],
    ({ pageParam = 1 }) => GetUpdatesComments({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//post updates
export const usePostUpdates = () => {
  const queryClient = useQueryClient()
  return useMutation(PostUpdates)
}
//post updates comments
export const usePostUpdatesComments = (data: {
  invalidateKeys: any[]
  cb?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation(PostUpdatesComment, {
    onSuccess: () => {
      data.invalidateKeys.map((keyArr) => queryClient.invalidateQueries(keyArr))

      data?.cb && data?.cb()
    },
    onError: (err) => {},
  })
}
//delete updates
export const useDeleteUpdate = () => {
  return useMutation(DeleteUpdate)
}
//post updates media
export const usePostUpdatesMedia = () => {
  return useMutation(PostUpdatesMedia)
}
//toggle updates like
export const useToggleUpdatesLike = (data: {
  invalidateKeys: any[]
  errCb?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation(ToggleUpdatesLike, {
    onSuccess: () => {
      data.invalidateKeys.map((key) => queryClient.invalidateQueries(key))
    },
    onError: (err) => {
      data?.errCb && data?.errCb()
      // errorHandler(err)
    },
  })
}

//get single update
export const useGetUpdate = (data: GetItemQueryDTO) => {
  return useQuery([CREATE_DOCUMENT + data.id], () => GetUpdate(data), {})
}

/***
 *
 * Access tab
 */

//get user investors
export const useGetUsersInvestors = (
  data: Omit<GetUsersInvestorsQueryDTO, 'page'>
) => {
  return useInfiniteQuery(
    [USERS_INVESTORS, data],
    ({ pageParam = 1 }) => GetUsersInvestors({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}
//get access list
export const useGetAccessList = (data: Omit<GetFilesQueryDTO, 'page'>) => {
  return useInfiniteQuery(
    [
      GET_ACCESS_LIST,
      {
        query: data.query,
        startup: data.startup,
        status: data.status,
      },
    ],
    ({ pageParam = 1 }) => GetAccessList({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//delete access
export const useDeleteAccessReq = (data: {
  invalidateKeys: any[]
  cb?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation(DeleteAccessReq, {
    onSuccess: () => {
      data.invalidateKeys.map((key) => queryClient.invalidateQueries(key))
      data.cb && data.cb()
    },
  })
}
// updates access
export const useUpdateAccess = (data: {
  invalidateKeys: any[]
  cb?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation(UpdateAccessReq, {
    onSuccess: () => {
      data.invalidateKeys.map((key) => queryClient.invalidateQueries(key))
      data.cb && data.cb()
    },
  })
}
// grant access
export const useGrantAccess = (data: {
  invalidateKeys: any[]
  cb?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation(GrantAccessReq, {
    onSuccess: () => {
      data.invalidateKeys.map((key) => queryClient.invalidateQueries(key))
      data.cb && data.cb()
    },
  })
}
