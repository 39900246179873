import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import React from 'react'
import { FaEye } from 'react-icons/fa'
import { useNavigate } from 'react-router'

import { imagesUrl } from '@/assets/images/imageUrls'
import { StartUpStructure } from '@/apis/networkApis'
import {
  useChangeConnectionStatus,
  useCreateConnection,
  useDeleteConnection,
} from '@/store/networkStore'
import { CONNECTIONS } from '@/constants/querryKeys'
import ConnectionBtn from '@/common/ConnectionBtn'

interface Props {
  id: number
  img: string
  name: string
  tags: string[]
  desc: string
  onClickFollow?: (id: string) => void
  onClickView?: (item: Props) => void
}

function StartupCard({
  id,
  country,
  name,
  members,
  logo,
  industry,
  connection,
  about_us,
}: Partial<StartUpStructure>) {
  const navigate = useNavigate()

  return (
    <div className='flex p-2 flex-row h-fit gap-1 rounded-lg bg-[white] md:flex-1 md:h-[410px] md:gap-3  md:justify-between md:flex-col md:p-0'>
      <img
        src={logo || imagesUrl.blanckCompImg}
        alt='start_up_logo'
        className='w-[87px] h-[75px] rounded-[8px] object-center object-cover md:rounded-t-lg md:w-full md:h-[183px]'
      />
      <div className='flex flex-col gap-2 md:justify-between md:flex-1'>
        <div className='flex flex-col gap-2 px-5 py-2'>
          <div className='flex gap-2'>
            <small
              className={`bg-blue-300 font-bold rounded p-1 uppercase text-black-1`}
            >
              {industry}
            </small>
          </div>
          <Typography heading='xs'>{name}</Typography>

          <small>{about_us}</small>
        </div>

        <div className='flex items-center justify-between w-full  gap-2 px-5 pb-3'>
          <ConnectionBtn
            createConnectionObj={{
              id: +id!,
              type: 'startup',
            }}
            invalidateKey={[CONNECTIONS]}
            connection={connection! as any}
            createConnectionSx='!w-full'
            deleteSx='!w-full'
            unConnectSx='!w-full'
          />

          <Button
            className='rounded px-6 border w-full'
            onClick={() =>
              navigate(`/app/networks/startups/${name}`, {
                state: {
                  id,
                },
              })
            }
            size='sm'
            color='white'
          >
            <span className='flex gap-2 items-center justify-center'>
              <FaEye /> View
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default StartupCard
