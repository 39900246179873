import {
  AcceptInvite,
  CreateAccount,
  CreateStartup,
  ForgotPassword,
  ResendVerificationLink,
  ResetPassword,
  Signin,
  VerifyAccount,
} from '@/apis/authApis'
import { STARTUPS } from '@/apis/endpoints'
import { useMutation, useQueryClient } from 'react-query'

export const useAcceptInvite = () => {
  return useMutation(AcceptInvite)
}
export const useCreateAccount = () => {
  return useMutation(CreateAccount)
}

export const useCreateStartup = () => {
  const qc = useQueryClient()
  return useMutation(CreateStartup, {
    onSuccess() {
      qc.invalidateQueries([STARTUPS, { page: 1, member: true }])
      qc.invalidateQueries([STARTUPS])
    },
  })
}

export const useVerifyAccount = () => {
  return useMutation(VerifyAccount)
}

export const useSendVerifcationLink = () => {
  return useMutation(ResendVerificationLink)
}

export const useForgotPassword = () => {
  return useMutation(ForgotPassword)
}

export const useResetPassword = () => {
  return useMutation(ResetPassword)
}
export const useSignin = () => {
  return useMutation(Signin)
}
