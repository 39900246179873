import iconComponents from '@/assets/icons/iconComponents'
import useGetCssValue from '@/hooks/useGetCssValue'

import { useOutsideClick } from '@/hooks/useOutsideClick'
import clsx from 'clsx'
import React, {
  FC,
  HTMLAttributes,
  MouseEventHandler,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'

interface IMoreButton extends HTMLAttributes<HTMLSelectElement> {
  optionsValue?: (item: any) => string
  optionsText?: (item: any) => React.ReactNode
  options: any[]
  name?: string
  placeholder?: string
  fullWidth?: boolean
  error?: string
  value?: string
  minWidth?: string
  menuClassName?: string
  disabled?: boolean
  loading?: boolean
  CustomComp?: ReactElement
}
const MoreButton: FC<IMoreButton> = ({
  placeholder,
  optionsValue,
  optionsText,
  options,
  onChange,
  name,
  id,
  className,
  menuClassName,
  error,
  disabled,
  value,
  minWidth,
  CustomComp,
  style,
  ...rest
}) => {
  const [menuTopPosition, setMenuTopPosition] = useState(24)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const outerRef = useRef<HTMLDivElement>(null)
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  anchorRef.current = anchorEl
  const clickHandler: MouseEventHandler = (e) => {
    e.preventDefault()
    if (anchorEl) {
      setAnchorEl(null)
    } else setAnchorEl(e.currentTarget as HTMLButtonElement)
  }
  const [menuRefEle, menuHeight] = useGetCssValue(
    'height',
    '350px',
    Boolean(anchorEl)
  )
  useOutsideClick([anchorRef, menuRefEle], () => setAnchorEl(null))

  useEffect(() => {
    const heightFromTop =
      outerRef.current && outerRef.current.getBoundingClientRect().top
    if (!heightFromTop || !Boolean(anchorEl)) return
    if (parseInt(menuHeight) + heightFromTop + 24 > window.innerHeight) {
      setMenuTopPosition(-parseInt(menuHeight))
    } else {
      setMenuTopPosition(24)
    }
  }, [anchorEl, menuHeight])

  return (
    <div ref={outerRef} style={style} className='relative min-w-max'>
      {CustomComp
        ? React.cloneElement(CustomComp, {
            ...rest,
            disabled,
            type: 'button',
            onClick: clickHandler,
            id,
            className: clsx(className ? className : ''),
          })
        : React.createElement(
            'button',
            {
              ...rest,
              disabled,
              type: 'button',
              onClick: clickHandler,
              id,
              className: clsx(
                `flex-1 flex justify-center rounded-[10px]  items-center fill-black stroke-black`,
                className ? className : ''
              ),
            } as any,
            <iconComponents.util.MoreVertIcon className='fill-inherit stroke-inherit ' />
          )}

      {Boolean(anchorEl) && (
        <ul
          style={{
            top: `${menuTopPosition}px`,
          }}
          ref={menuRefEle}
          className={`flex-col rounded-[5px] min-w-[165px] items-center justify-start bg-white border
          shadow-[0px_4px_4px_0px_#0000001A] border-wb-neutral-200
          absolute left-0 z-30 mr-3 overflow-auto w-max h-max max-h-[350px] ${
            Boolean(anchorEl) ? 'flex' : 'hidden'
          } ${menuClassName || ''}`}
        >
          {options.map((option, index) => {
            return (
              <li
                style={{ minWidth: minWidth || '180px' }}
                key={index}
                className={`hover:bg-sifuse-shades-200 hover:text-black p-5 text-wb-dark-green w-full cursor-pointer whitespace-nowrap relative z-40 left flex justify-between items-center gap-2.5
            `}
                role='menuitem'
                onClick={(e) => {
                  onChange?.({
                    target: {
                      name,
                      value: optionsValue ? optionsValue(option) : option,
                    },
                    currentTarget: {
                      name,
                      value: optionsValue ? optionsValue(option) : option,
                    },
                  } as any)
                  setAnchorEl(null)
                }}
              >
                {optionsText ? optionsText(option) : option}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default MoreButton
