import api from './api'
import { ApiRequestConfig, ServerRes } from './api.types'
import { PersonalInfoReqDTO } from './authApis'
import { FILES_URL } from './constants/api'
import {
  CHANGE_PASSWORD,
  HIGHLIGHTS,
  REQUEST_PRESIGNED_URL,
  STARTUPS,
  USERS,
} from './endpoints'
import { Startup } from './startupApis'

export type ChangePasswordReqDTO = {
  new_password: string
  old_password: string
}
export type RequestPresignedUrlReqDTO = {
  action: 'PUT' | 'DELETE'
  module_name: string
  files: {
    name: string
    type?: string
  }[]
}

export type PutFiles = {
  module_name: string
  files: File[]
}

type RequestPresignedUrlResDTO = {
  action: 'PUT' | 'DELETE'
  signed_urls: string[]
}
export type Highlight = {
  startup: number
  content: string
  id: number
}
export type HighlightReqDTO = {
  startup: number
  content: string
}
export const ChangePassword = async (data: ChangePasswordReqDTO) => {
  return await api.post<ServerRes<any>>(CHANGE_PASSWORD, data)
}

const requestPresignedUrl = async (data: RequestPresignedUrlReqDTO) => {
  return (
    await api.post<ServerRes<RequestPresignedUrlResDTO>>(
      REQUEST_PRESIGNED_URL,
      data
    )
  ).data.data
}

export const PutFilesToStore = async (
  data: PutFiles,
  configs?: ApiRequestConfig[]
) => {
  const { signed_urls, action } = await requestPresignedUrl({
    module_name: data.module_name,
    action: 'PUT',
    files: data.files.map((file) => ({
      name: file.name,
      type: file.type,
    })),
  })
  if (action !== 'PUT') {
    throw new Error('Bad Url Response')
  }

  const results = await Promise.all(
    signed_urls.map((url, i) => {
      return api.put(url, data.files[i], {
        ...(configs?.length ? configs[i] : {}),
        // url,
        // method: 'PUT',
        baseURL: '',
        withCredentials: false,
        ignoreAuthentication: true,
        headers: {
          'Content-Type': data.files[i].type,
        },
      })
    })
  )

  return results.map((res, i) => {
    const Url = new URL(signed_urls[i])

    return { url: FILES_URL + Url.pathname }
  })
}
export const DeleteFilesFromStore = async (
  data: Omit<RequestPresignedUrlReqDTO, 'action'>
) => {
  const { signed_urls } = await requestPresignedUrl({
    action: 'DELETE',
    ...data,
  })
  return (
    await Promise.all(
      signed_urls.map((url) => fetch(url, { method: 'DELETE' }))
    )
  ).map((res) => res)
}
export const UpdateStartup = async (data: FormData) => {
  const id = data.get('id')
  data.delete('id')
  return await api.patch<ServerRes<Startup>>(STARTUPS + `${id}/`, data)
}

export const RemoveCompanyLogo = async (data: { startup: number }) => {
  return await api.patch<ServerRes<any>>(
    STARTUPS + `${data.startup}/remove-logo/`,
    {}
  )
}

export const UpdateUser = async (data: FormData) => {
  const id = data.get('id')
  data.delete('id')
  return await api.patch<ServerRes<PersonalInfoReqDTO>>(USERS + `${id}/`, data)
}

export const RemoveUserAvatar = async (data: { user: number }) => {
  return await api.patch<ServerRes<any>>(
    USERS + `${data.user}/remove-avatar/`,
    {}
  )
}

export const CreateHighlight = async (data: HighlightReqDTO) => {
  return await api.post<ServerRes<Highlight>>(HIGHLIGHTS, data)
}
export const UpdateHightlight = async (data: {
  highlight: number
  content: string
}) => {
  return await api.patch<ServerRes<Highlight>>(
    HIGHLIGHTS + `${data.highlight}/`,
    { content: data.content }
  )
}
export const DeleteHightlight = async (data: { highlight: number }) => {
  return await api.delete<ServerRes<any>>(HIGHLIGHTS + `${data.highlight}/`)
}
export const Hightlights = async (data: { startup: number }) => {
  return (
    await api.get<ServerRes<Array<Highlight>>>(
      HIGHLIGHTS + `?startup=${data.startup}&page=1`
    )
  ).data.data
}
