import { Startup } from '@/apis/startupApis'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import MoreButton from '@/common/button/MoreButton'
import Modal from '@/common/modal/Modal'
import ModalHeader from '@/common/modal/ModalHeader'
import { IOpenable } from '@/common/modal/modal.type'
import {
  useAuthActionsContext,
  useAuthContext,
} from '@/contexts/AuthContextProvider'
import { useToggleState } from '@/hooks/useToggleState'
import { Pages } from '@/routes/route.constant'
import { useGetStartups } from '@/store/startupStore'

import { FC, useState } from 'react'
import { flushSync } from 'react-dom'
import { useNavigate } from 'react-router'

const SelectAccount = () => {
  const { data: paginatedInvestors, isLoading } = useGetStartups({
    page: 1,
    member: true,
    page_size: 1000,
  })
  const { switchAccount } = useAuthActionsContext()
  const { getAuthState } = useAuthContext()

  const navigate = useNavigate()
  const userState = getAuthState()
  const [blockedAccount, setBlockedAccount] = useState<Startup | null>(null)
  const {
    open: openBlockedModal,
    close: closeBlockedModal,
    state: isBlockedModalOpen,
  } = useToggleState()

  return (
    <article className='flex flex-col justify-center items-start gap-[60px] w-full '>
      <header className='w-full'>
        <hgroup className='flex flex-col items-center w-full'>
          <Typography as='h2' heading='xl' className='text-center'>
            Select Option
          </Typography>
          <Typography as='p' paragraph='lg' color='var(--shades500)'>
            Below are options to select to continue with
          </Typography>
        </hgroup>
      </header>
      <main className='flex flex-col items-stretch gap-6 w-full pt-8 justify-center px-[100px]'>
        <Button
          onClick={() => {
            flushSync(() =>
              switchAccount({
                accountType: 'user',
                ...(userState ? userState : ({} as any)),
              })
            )
            navigate(`/app/${Pages.Network}`)
          }}
          size='xl'
          color='white'
          className='!bg-sifuse-shades-75'
        >
          Continue as an Individual
        </Button>
        <MoreButton
          className='!bg-sifuse-shades-75 !self-stretch w-full'
          options={paginatedInvestors?.pages[0]?.results || []}
          optionsText={(item) => (
            <span
              style={{
                color:
                  item.blocked || !item.is_active
                    ? 'var(--shades500)'
                    : 'var(--shades900)',
              }}
              className='w-full'
            >
              {item?.name}
            </span>
          )}
          optionsValue={(item) => item?.id}
          menuClassName='!top-[46px] left-auto right-4'
          onChange={(e) => {
            const { value } = e.currentTarget
            const res = paginatedInvestors?.pages[0]?.results.find(
              (el) => el.id === Number(value)
            )
            if (!res) return
            if (!res.is_active) {
              flushSync(() => setBlockedAccount(res))
              return openBlockedModal()
            }

            flushSync(() => switchAccount({ accountType: 'business', ...res }))
            navigate(`/app/${Pages.Network}`)
          }}
          loading={isLoading}
          CustomComp={
            <Button
              size='xl'
              color='white'
              className='!bg-sifuse-shades-75 !self-stretch'
              endIcon={
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12 16.7996C11.3 16.7996 10.6 16.5296 10.07 15.9996L3.55002 9.47965C3.26002 9.18965 3.26002 8.70965 3.55002 8.41965C3.84002 8.12965 4.32002 8.12965 4.61002 8.41965L11.13 14.9396C11.61 15.4196 12.39 15.4196 12.87 14.9396L19.39 8.41965C19.68 8.12965 20.16 8.12965 20.45 8.41965C20.74 8.70965 20.74 9.18965 20.45 9.47965L13.93 15.9996C13.4 16.5296 12.7 16.7996 12 16.7996Z'
                    fill='#000014'
                  />
                </svg>
              }
            >
              Continue as a Business
            </Button>
          }
        />
      </main>
      <BlockedModal
        data={blockedAccount!}
        onClose={closeBlockedModal}
        open={isBlockedModalOpen}
      />
    </article>
  )
}

export const BlockedModal: FC<IOpenable<Startup>> = ({
  onClose,
  data,
  open,
}) => {
  return (
    <Modal onClose={onClose} open={open}>
      <ModalHeader
        onClose={onClose}
        title={
          !data?.is_active
            ? `Sifuse Admin Blocked Your Business`
            : `Your Business has blocked you.`
        }
      />
      <main className='w-full pt-2 p-6'>
        <Typography>Kindly Reach out to the admin </Typography>
      </main>
    </Modal>
  )
}

export default SelectAccount
