import React from 'react'
import ButtonTab from './components/ButtonTab'
import TextInput from '@/common/TextInput'
import { ReactComponent as SearchIcon } from '@/assets/network/search-normal.svg'

import { useLocation, useNavigate } from 'react-router'

import SearchResults from './components/SearchResults'
import { FaX } from 'react-icons/fa6'

import { CONNECTIONS } from '@/constants/querryKeys'
import Typography from '@/common/Typography'
import { imagesUrl } from '@/assets/images/imageUrls'
import Button from '@/common/button/Button'

import LoadingState from './components/LoadingState'

import Modal, { RefType } from '@/common/Modal'
import SinglePost from './components/SinglePost/SinglePost'
import {
  useChangeConnectionStatus,
  useDeleteConnection,
  useGetConnections,
  useSearchTimeline,
} from '@/store/networkStore'

import Title from '@/common/Title'
import { useBreadCrumbs } from '@/layouts/app/components/header'

import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'

import { ConnectionResDTO } from '@/apis/networkApis'

import clsx from 'clsx'
import FollowingItem from './components/FollowingItem'
import { useAuthContext } from '@/contexts/AuthContextProvider'

interface SearchData {
  posts: { count: number; posts: any[] }
  startups: {
    count: number
    startups: any[]
  }
  users: { count: number; users: any[] }
}

const Connection = ({ item }: { item: ConnectionResDTO }) => {
  const navigate = useNavigate()

  //accept Connection
  const { mutateAsync: acceptConnection, isLoading: acceptConnectionLoading } =
    useChangeConnectionStatus({
      invalidateKey: [CONNECTIONS],
    })

  // delete connection
  const { mutate: deleteConnection, isLoading: deleteConnectionLoading } =
    useDeleteConnection({
      invalidateKey: [CONNECTIONS],
    })

  return (
    <div className='flex flex-col gap-2 border border-[#F2F2F2] p-4'>
      <div className='flex gap-2 items-center'>
        <img
          src={
            item?.receiver?.img || item?.receiver?.type === 'Individual'
              ? imagesUrl.blanckProfileImg
              : imagesUrl.blanckCompImg
          }
          alt='profile_pix'
          className={clsx('w-[32px] h-[32px] rounded-full', {
            '!rounded-sm': item?.receiver?.type !== 'Individual',
          })}
        />

        <Typography
          paragraph='sm'
          className='cursor-pointer'
          onClick={() =>
            navigate(
              item?.receiver?.type === 'Business'
                ? `/app/networks/startups/${item?.receiver?.name}`
                : item?.receiver?.type === 'Group'
                ? `/app/networks/group/${item?.receiver?.name}`
                : item?.receiver?.type === 'Entity'
                ? `/app/networks/funds/${item?.receiver?.name}`
                : `/app/networks/user/${item?.receiver?.name}`,
              {
                state: {
                  id: item?.receiver?.id,
                  fromName: 'My Network',
                  from: '/app/networks/mynetwork',
                  excludeCount: 2,
                },
              }
            )
          }
        >
          {item?.receiver?.name}
        </Typography>
      </div>

      {item?.message && (
        <Typography className='!text-[14px]'>{item?.message}</Typography>
      )}

      <div className='flex w-full gap-1 items-center justify-end'>
        {' '}
        <Button
          size='xs'
          color='green'
          className='px-3'
          onClick={() => acceptConnection({ id: item?.id, status: 'Accepted' })}
          loading={acceptConnectionLoading}
        >
          Accept
        </Button>{' '}
        <Button
          size='xs'
          color='lightred'
          className='px-3'
          onClick={() => deleteConnection({ id: item?.id })}
          loading={deleteConnectionLoading}
        >
          Decline
        </Button>{' '}
      </div>
    </div>
  )
}

const PendingConnections = () => {
  //get connections
  const {
    data: connectionsApi,
    isLoading: connectionsLoading,
    isError: isConnectionsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetConnections({ status: 'Pending' })

  const pending = (connectionsApi as any)?.pages?.flatMap(
    (pg: any) => pg.data.data.results
  ) as ConnectionResDTO[]
  const isEmpty = !pending?.length
  const count = (connectionsApi as any)?.pages?.[0]?.data?.data?.count

  // console.log(pending)
  return (
    <div className='flex flex-col gap-2 w-full'>
      <div className='flex flex-col gap-1'>
        <Typography heading='3xs'>Pending Connection Request</Typography>

        {isEmpty || connectionsLoading || isConnectionsError ? null : (
          <Typography paragraph='xs'>
            {count} {count === 1 ? 'person' : 'people'}
          </Typography>
        )}
      </div>
      {
        //if first load

        <LoadingState
          condition={
            connectionsLoading
              ? 'isLoading'
              : isEmpty
              ? 'isEmpty'
              : isConnectionsError
              ? 'isError'
              : ''
          }
          isEmptyDisplay={
            <>
              <Typography heading='3xs'>
                You have no pending connections
              </Typography>
            </>
          }
        />
      }
      {!connectionsLoading && !isConnectionsError && (
        <InfiniteScrollContainer
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          containerClass='min-h-[20vh]'
        >
          <div className='flex flex-col gap-2 max-h-[70vh] overflow-auto'>
            {pending?.map((connection) => (
              <Connection key={connection.id} item={connection} />
            ))}
          </div>
        </InfiniteScrollContainer>
      )}
    </div>
  )
}

const BreadCrumbMap = new Map()
BreadCrumbMap.set('/app/networks', 'My Network')
function MyNetwork() {
  useBreadCrumbs(BreadCrumbMap)
  const navigate = useNavigate()
  const location = useLocation()
  const { currentAccount } = useAuthContext()

  const modalRef = React.useRef<RefType>(null)
  const modalRef1 = React.useRef<RefType>(null)

  const [search, setsearch] = React.useState<string>('')

  const [postId, setpostId] = React.useState<number | null>()

  const handleTabclick = (path: string) => {
    navigate(path)
  }

  const toggleModal = (id: number): void => {
    setpostId(id)
    modalRef?.current?.handleToggle()
  }
  const toggleModal1 = (): void => {
    modalRef1?.current?.handleToggle()
  }

  //search timeline
  const { data: timelineSearchData, isLoading: timelineSearchDataLoading } =
    useSearchTimeline({ search })
  const searchDataMain = timelineSearchData as unknown as SearchData

  //get connections
  const {
    data: connectionsApi,
    isLoading: connectionsLoading,
    isError: isConnectionsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetConnections({ status: 'Accepted' })

  const followings = (connectionsApi as any)?.pages?.flatMap(
    (pg: any) => pg.data.data.results
  ) as ConnectionResDTO[]
  const isEmpty = !followings?.length
  const count = (connectionsApi as any)?.pages?.[0]?.data?.data?.count

  return (
    <div className=''>
      <Title>My Network</Title>
      <div className='flex flex-col justify-between items-start md:items-center md:flex-row width-full'>
        <ButtonTab
          activeProperty={location.pathname}
          onClick={handleTabclick}
        />
        <div className='flex gap-2'>
          <div className='relative w-[69vw] sm:w-auto'>
            <TextInput
              placeholder='Search my network'
              name='search'
              value={search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setsearch(e.target.value)
              }
              startIcon={<SearchIcon />}
              endIcon={
                !!search && (
                  <FaX
                    className='text-[0.7rem]'
                    onClick={() => setsearch('')}
                  />
                )
              }
              className='w-full border border-gray-200 sm:h-[48px] md:w-[200px] lg:w-[364px]'
            />
            <SearchResults
              isOpen={!!search}
              investors={searchDataMain?.users}
              startups={searchDataMain?.startups}
              posts={searchDataMain?.posts}
              toggleModal={toggleModal}
            />
          </div>
          <Button className='md:hidden !text-[12px]' onClick={toggleModal1}>
            Pending Requests
          </Button>
        </div>
      </div>
      <div className='flex gap-3 flex-col items-start sm:flex-row '>
        <div className='w-full bg-[white] border border-[#E6E6EB] mt-2 h-fit-content min-h-[20vh] rounded-lg  md:w-[60%] lg:w-[70%] sm:mt-6'>
          {
            //if first load

            <LoadingState
              condition={
                connectionsLoading
                  ? 'isLoading'
                  : isEmpty
                  ? 'isEmpty'
                  : isConnectionsError
                  ? 'isError'
                  : ''
              }
              isEmptyDisplay={
                <>
                  <Typography heading='3xs'>
                    You have no connections yet
                  </Typography>
                </>
              }
            />
          }
          {!connectionsLoading && !isConnectionsError && (
            <InfiniteScrollContainer
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              containerClass='min-h-[40vh]'
            >
              <div className='flex w-full flex-col gap-3 items-start p-4'>
                <div className='flex flex-col gap-3 items-start w-full'>
                  {count !== 0 ? (
                    <p className='font-light'>
                      You are connected to {count}{' '}
                      {count === 1 ? 'person' : 'people'}
                    </p>
                  ) : null}

                  {followings.map((item) => (
                    <FollowingItem
                      key={item.id}
                      item={
                        +currentAccount?.id! === +item?.sender?.id!
                          ? item.receiver
                          : item.sender
                      }
                      connectionId={item.id}
                    />
                  ))}
                </div>
              </div>
            </InfiniteScrollContainer>
          )}
        </div>
        <div className='hidden w-full flex-col gap-3 items-start bg-[white] p-4 border border-[#E6E6EB] mt-2 rounded-lg p-4 md:flex md:w-[40%]  lg:w-[30%] sm:mt-6'>
          <PendingConnections />
        </div>
      </div>

      <Modal
        ref={modalRef}
        modalClass='w-[90vw] sm:w-[800px] min-h-[80vh] pt-2'
      >
        <SinglePost id={postId} />
      </Modal>

      <Modal ref={modalRef1} modalClass='w-[90vw] pt-2' showCloseBtn>
        <PendingConnections />
      </Modal>
    </div>
  )
}

export default MyNetwork
