import { PaginationReq } from '@/apis/api.types'
import {
  GET_STARTUPS,
  GET_STARTUPS_HIGHLIGHTS,
  STARTUPS,
  STARTUP_ASSESSMENT,
  STARTUP_ASSESSMENT_DATA,
  STARTUP_INVESTORS,
  USERS_INVESTORS,
} from '@/apis/endpoints'
import {
  AddInvestor,
  CreateAssessment,
  CreateFunding,
  GetAssessment,
  GetAssessmentData,
  GetInterestedInvestors,
  GetInvestors,
  GetSystemInvestors,
  Hightlights,
  MyStartUp,
  MyStartUps,
  PublishStartup,
  RemoveInvestor,
  StartupDetail,
  UpdateAssessment,
  UpdateFunding,
} from '@/apis/startupApis'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

export const useGetStartups = (data: PaginationReq) => {
  return useInfiniteQuery(
    [STARTUPS, data],
    ({ pageParam }) => MyStartUps({ ...data, page: pageParam }),
    {
      getNextPageParam(lastPage) {
        if (!lastPage.next) return undefined
        const page = new URL(lastPage.next).searchParams.get('page')
        return page
      },
    }
  )
}

export const useGetInterestedInvestors = (
  data: PaginationReq<{ startupId: number }>
) => {
  return useInfiniteQuery(
    [STARTUPS + data.startupId + '/interested-investors/'],
    ({ pageParam = 1 }) => {
      return GetInterestedInvestors({ ...data, page: pageParam })
    },
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

export const useGetInvestors = (data: PaginationReq) => {
  return useInfiniteQuery(
    [STARTUP_INVESTORS, data],
    ({ pageParam = 1 }) => {
      return GetInvestors({ ...data, page: pageParam })
    },
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

export const useGetSystemInvestors = (data: PaginationReq) => {
  return useInfiniteQuery(
    [USERS_INVESTORS, data],
    ({ pageParam = 1 }) => {
      return GetSystemInvestors({ ...data, page: pageParam })
    },
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

export const useAddInvestor = () => {
  const qc = useQueryClient()
  return useMutation(AddInvestor, {
    onSuccess() {
      qc.invalidateQueries([STARTUP_INVESTORS])
    },
  })
}

export const useRemoveInvestor = () => {
  const qc = useQueryClient()
  return useMutation(RemoveInvestor, {
    onSuccess() {
      qc.invalidateQueries([STARTUP_INVESTORS])
    },
  })
}

export const useGetStartupDetail = (startupId: number) => {
  return useQuery(
    [STARTUPS, startupId],
    () => MyStartUp({ startup: startupId }),
    { enabled: !!startupId }
  )
}

export const usePublishStartup = () => {
  const qc = useQueryClient()
  return useMutation(PublishStartup, {
    onSuccess(data, variables, context) {
      qc.invalidateQueries([STARTUPS, variables.startup])
    },
  })
}

export const useGetAssessmentData = () => {
  return useQuery([STARTUP_ASSESSMENT_DATA], GetAssessmentData)
}
export const useGetAssessment = (assessment: number) => {
  return useQuery(
    [STARTUP_ASSESSMENT, assessment],
    () => GetAssessment({ assessment }),
    { enabled: !!assessment }
  )
}
export const useCreateAssessment = () => {
  const qc = useQueryClient()
  return useMutation(CreateAssessment, {
    onSuccess(data, variables, context) {
      qc.invalidateQueries([STARTUPS, variables.startup])
    },
  })
}

export const useUpdateAssessment = () => {
  const qc = useQueryClient()
  return useMutation(UpdateAssessment, {
    onSuccess(data, variables, context) {
      qc.invalidateQueries([STARTUPS, variables.startup])
      qc.invalidateQueries([STARTUP_ASSESSMENT, variables.id])
    },
  })
}
export const useCreateFunding = () => {
  const qc = useQueryClient()
  return useMutation(CreateFunding, {
    onSuccess(data, variables, context) {
      qc.invalidateQueries([STARTUPS, variables.startup])
    },
  })
}

export const useUpdateFunding = () => {
  const qc = useQueryClient()
  return useMutation(UpdateFunding, {
    onSuccess(data, variables, context) {
      qc.invalidateQueries([STARTUPS, variables.startup])
      qc.invalidateQueries([STARTUP_ASSESSMENT, variables.id])
    },
  })
}

export const useGetStartup = (startup: number) => {
  return useQuery([GET_STARTUPS, startup], () => StartupDetail({ startup }), {
    enabled: !!startup,
  })
}
export const useGetHighlights = (startup: number) => {
  return useQuery(
    [GET_STARTUPS_HIGHLIGHTS, startup],
    () => Hightlights({ startup }),
    {
      enabled: !!startup,
    }
  )
}
