type ReturnData = {
  src: string
  userId: number
  name: string
  title: string
  navigateTo: string
  navigateState: Record<string, any>
  isUser: boolean
}

export const formatUserDetails = (data: Record<string, any>): ReturnData => {
  const returnData: ReturnData = {
    name: '',
    src: '',
    title: '',
    userId: 0,
    navigateTo: '',
    navigateState: {},
    isUser: false,
  }
  const key = !!data.business
    ? 'business'
    : !!data.investor_group
    ? 'investor_group'
    : !!data.fund_entity
    ? 'fund_entity'
    : 'user'

  returnData.name =
    data?.[key]?.name || `${data?.[key]?.first_name} ${data?.[key]?.last_name}`

  returnData.src =
    data?.[key]?.photo || data?.[key]?.logo || data?.[key]?.avatar
  returnData.title = data?.[key]?.about
  returnData.userId = data?.[key]?.id
  returnData.isUser = key === 'user' ? true : false

  returnData.navigateTo =
    key === 'business'
      ? `/app/networks/startups/${returnData.name}`
      : key === 'investor_group'
      ? `/app/networks/group/${returnData.name}`
      : key === 'fund_entity'
      ? `/app/networks/funds/${returnData.name}`
      : `/app/networks/user/${returnData.name}`

  returnData.navigateState = {
    id: returnData.userId,
    fromName: data?.fromName ? data?.fromName : 'Network',
    from: data?.from ? data?.from : '/app/networks',
    excludeCount: 0,
  }

  return returnData
}
