import { imagesUrl } from '@/assets/images/imageUrls'
import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import { STARTUP } from '@/constants/querryKeys'
import { useMessageRecipient } from '@/pages/message/hooks'
import { ReactComponent as AddUser } from '@/assets/icons/utils/addUser.svg'
import { useCreateConnection, useDeleteConnection } from '@/store/networkStore'
import React from 'react'
import { FaCheck, FaCheckSquare, FaEnvelope, FaPlus } from 'react-icons/fa'
import { FaX } from 'react-icons/fa6'

import { useNavigate, useParams } from 'react-router'
import { Pages } from '@/routes/route.constant'
import ConnectionBtn from '@/common/ConnectionBtn'

interface Props {
  id: number
  email: string
  first_name: string
  last_name: string
  phone_number: string
  user_type: 'Startup' | 'Investor' | 'Admin'
  email_verified: boolean
  date_joined: Date
  last_login: Date
  connection: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
  about: string
  avatar: string
}

function TeamInfoCard({
  id,
  email,
  first_name,
  last_name,
  phone_number,
  user_type,
  email_verified,
  date_joined,
  last_login,
  connection,
  about,
  avatar,
}: Props) {
  const navigate = useNavigate()
  const messageUser = useMessageRecipient()
  const { id: pgId } = useParams()
  const startupId = Number(pgId)

  return (
    <div className='flex w-[131px] h-[213px] gap-1 flex-col items-center justify-between  sm:gap-3'>
      <img
        src={avatar || imagesUrl.blanckProfileImg}
        alt='start_up_logo'
        className='h-[91.25px] w-[91.25px] object-center object-cover rounded-full '
      />
      <div className='flex flex-col gap-2 px-2 py-0 sm:px-3 sm:gap-3 w-full'>
        <div className='flex flex-col gap-0 items:start sm:items-center justify-center'>
          <Typography
            label='sm'
            onClick={() =>
              navigate(
                `/app/networks/${Pages.User}/${`${first_name} ${last_name}`}`,
                {
                  state: {
                    id,
                    from: 'startups',
                  },
                }
              )
            }
            className='cursor-pointer hover:shadow-sm '
          >
            <strong className='font-medium text-[14px]'>
              {' '}
              {`${first_name} ${last_name}`}
            </strong>
          </Typography>

          <small className={`font-light`}>{about || '-'}</small>
        </div>

        <div className='flex items-center justify-between w-full mt-1'>
          <ConnectionBtn
            createConnectionObj={{
              id: id!,
              type: 'startup',
            }}
            invalidateKey={[STARTUP, startupId]}
            connection={connection! as any}
            connectionElement={
              <Button
                color='white'
                className='rounded w-[40px] h-[32px] border'
                startIcon={<FaPlus />}
                size='sm'
              ></Button>
            }
            acceptConnectionElement={
              <Button
                color='green'
                className='rounded w-[40px] h-[32px] border'
                startIcon={<FaCheck />}
                size='sm'
              ></Button>
            }
            acceptedConnectionElement={
              <Button
                color='lightgreen'
                className='rounded w-[40px] h-[32px] border'
                startIcon={<FaCheck />}
                size='sm'
              ></Button>
            }
            deleteConnectionElement={
              <Button
                color='lightred'
                startIcon={<FaX />}
                className='rounded w-[40px] h-[32px] border'
                size='sm'
              ></Button>
            }
          />

          <Button
            className='rounded w-[40px] h-[32px] border'
            color='white'
            onClick={() => messageUser(id)}
          >
            <FaEnvelope />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TeamInfoCard
