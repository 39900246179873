/* eslint-disable react-hooks/exhaustive-deps */
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom'
import { lazy } from 'react'

import PublicRoutes from '@/routes/PublicRoutes'
import ProtectedRoutes from '@/routes/ProtectedRoutes'

import { Pages } from './route.constant'

import AuthenticationLayout from '@/layouts/auth'
import SuccessLayout from '@/layouts/success'
import ApplicationLayout from '@/layouts/app'

import Timeline from '@/pages/theNetwork/Timeline'
import MyNetwork from '@/pages/theNetwork/MyNetwork'
import SingleStartup from '@/pages/theNetwork/SingleStartup'
import SingleUser from '@/pages/theNetwork/SingleUser'
import TeamInfo from '@/pages/theNetwork/TeamInfo'

import Startups from '@/pages/theNetwork/Startups'

import SignUp from '@/pages/auth/SignUp'
import SignIn from '@/pages/auth/SignIn'
import ChangePassword from '@/pages/auth/ChangePassword'
import Setup from '@/pages/auth/Setup'
import JoinTeam from '@/pages/auth/JoinTeam'
import VerifyAccount from '@/pages/auth/VerifyAccount'

import File from '@/pages/auth/File'
import PublicFolder from '@/pages/auth/Folder'
import SelectAccount from '@/pages/auth/SelectAccount'

const DataRoom = lazy(() => import('@/pages/dataRoom'))
const Folder = lazy(() => import('@/pages/dataRoom/Folder'))
const GroupTeamMembers = lazy(
  () => import('@/pages/theNetwork/GroupTeamMembers')
)

const SingleGroup = lazy(() => import('@/pages/theNetwork/SingleGroup'))
const SingleFund = lazy(() => import('@/pages/theNetwork/SingleFund'))

const NetworkInvestors = lazy(() => import('@/pages/theNetwork/Investors'))

const Profile = lazy(() => import('@/pages/profile'))

const AllActivities = lazy(() => import('@/pages/profile/AllActivities'))
const Assessment = lazy(() => import('@/pages/profile/Assessment'))

const EventsPage = lazy(() => import('@/pages/events'))
const EventDetails = lazy(() => import('@/pages/events/EventDetails'))

const Messages = lazy(() => import('@/pages/message'))

const Notifications = lazy(() => import('@/pages/notification'))
const Communities = lazy(() => import('@/pages/community'))
const Explore = lazy(() => import('@/pages/community/Explore'))
const SingleCommunity = lazy(() => import('@/pages/community/SingleCommunity'))
const UsersRoles = lazy(() => import('@/pages/usersAndRoles'))
const CatchAllAuthRoute = () => {
  return <Navigate to={`/auth/${Pages.SignIn}`} />
}

const RouteConfig = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<div>App Crash</div>}>
      <Route element={<PublicRoutes />}>
        <Route path='/auth' element={<AuthenticationLayout />}>
          <Route index element={<Navigate to={`/auth/${Pages.SignIn}`} />} />
          <Route path={Pages.SignIn} element={<SignIn />} />
          <Route path={Pages.SignUp} element={<SignUp />} />
          <Route path={`${Pages.File}/:fileName/:id`} element={<File />} />
          <Route
            path={`${Pages.Folder}/:folderName/:id`}
            element={<PublicFolder />}
          />
          <Route path={Pages.ChangePassword} element={<ChangePassword />} />
          <Route path={Pages.VerifyAccount} element={<VerifyAccount />} />
          <Route path={Pages.JoinTeam} element={<JoinTeam />} />
        </Route>
        <Route path='*' element={<CatchAllAuthRoute />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route element={<ProtectedRoutes />}>
          <Route path='/success' element={<AuthenticationLayout />}>
            <Route path={Pages.SignIn} element={<SelectAccount />} />
          </Route>
          <Route path='/success' element={<SuccessLayout />}>
            <Route index element={<Setup />} />
          </Route>
          <Route path='/app' element={<ApplicationLayout />}>
            <Route index element={<Navigate to={Pages.Network} />} />
            <Route path={Pages.Network}>
              <Route index element={<Timeline />} />
              <Route path={Pages.MyNetwork} element={<MyNetwork />} />
              <Route path={Pages.AllPeople} element={<NetworkInvestors />} />

              <Route
                path={`${Pages.User}/:username`}
                element={<SingleUser />}
              />
              <Route
                path={`${Pages.Fund}/:fundname`}
                element={<SingleFund />}
              />

              <Route path={`${Pages.Group}/:groupname`}>
                <Route index element={<SingleGroup />} />
                <Route
                  path={`${Pages.TeamInfo}`}
                  element={<GroupTeamMembers />}
                />
              </Route>

              <Route path={Pages.Startups}>
                <Route index element={<Startups />} />
                <Route path=':startupName' element={<SingleStartup />} />
                <Route
                  path={`:startupName/${Pages.TeamInfo}`}
                  element={<TeamInfo />}
                />
              </Route>
            </Route>

            <Route path={Pages.Event}>
              <Route index element={<EventsPage />} />
              <Route path=':eventName' element={<EventDetails />} />
            </Route>

            <Route path='events' element={<EventsPage />} />

            <Route path={Pages.DataRoom}>
              <Route index element={<DataRoom />} />

              <Route path={`:folderName`} element={<Folder />} />
            </Route>

            <Route path={Pages.StartupProfile}>
              <Route index element={<Profile />} />
              <Route path={Pages.AllActivities} element={<AllActivities />} />
              <Route path={Pages.Assessment} element={<Assessment />} />
            </Route>
            <Route path={Pages.UsersRoles}>
              <Route index element={<UsersRoles />} />
            </Route>
            <Route path={Pages.Message} element={<Messages />} />
            <Route path={Pages.Notification} element={<Notifications />} />

            <Route path={Pages.Community}>
              <Route index element={<Communities />} />
              <Route
                path={`${Pages.Explore}/${Pages.AllCommunities}`}
                element={<Explore />}
              />
              <Route path={':communityName'}>
                <Route index element={<SingleCommunity />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path='*' element={<div>Not Found Page</div>} />
      </Route>
    </Route>
  )
)

export default RouteConfig
