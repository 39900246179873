import { ServerRes } from '@/apis/api.types'
import { GET_GROUPS, GET_INVESTORS_GROUP } from '@/apis/endpoints'
import {
  ChangeConnectionStatus,
  CreateConnection,
  CreatePost,
  CreatePostMedia,
  DeleteConnection,
  DeletePost,
  DeleteUserMembership,
  GetComments,
  GetConnections,
  GetInvestorsGroup,
  GetMentions,
  GetPost,
  GetPosts,
  GetRecomendedInvestors,
  GetStartup,
  GetStartupHighlights,
  GetStartups,
  GetTimelineActivities,
  GetTrendingStartups,
  GetUser,
  GetUsers,
  JoinUserGroup,
  PostComment,
  SearchTimeline,
  ToggleLike,
  getGroupDetails,
} from '@/apis/networkApis'
import { ToastNotify } from '@/common/toastManager'
import {
  COMMENTS,
  CONNECTIONS,
  GET_TIMELINE_ACTIVITIES,
  POST,
  POSTS,
  POST_MENTIONS,
  RECOMMENDED_INVESTORS,
  STARTUP,
  STARTUP_HIGHLIGHTS,
  TIMELINE_SEARCH,
  TRENDING_STARTUPS,
  USER,
} from '@/constants/querryKeys'
import { errorHandler } from '@/helpers/withAsync'
import { FileToUpload } from '@/pages/theNetwork/components/utils/types'
import { AxiosResponse } from 'axios'
import {
  QueryKey,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

//deny connection

export const useChangeConnectionStatus = (data: { invalidateKey: any[] }) => {
  const queryClient = useQueryClient()

  return useMutation(ChangeConnectionStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(data.invalidateKey)
      ToastNotify('success', {
        message: 'Success',
      })
    },
    onError: (err) => {
      errorHandler(err)
    },
  })
}

//post comment

export const usePostComment = (data: {
  invalidateKeys: any[]
  cb?: () => void
}) => {
  const queryClient = useQueryClient()

  return useMutation(PostComment, {
    onSuccess: () => {
      data.invalidateKeys.map((keyArr) => queryClient.invalidateQueries(keyArr))
      // ToastNotify('success', {
      //   message: 'Success',
      // })
      data?.cb && data?.cb()
    },
    onError: (err) => {
      // errorHandler(err)
    },
  })
}

//toggle like
export const useToggleLike = (data: {
  invalidateKeys: any[]
  errCb?: () => void
}) => {
  const queryClient = useQueryClient()

  return useMutation(ToggleLike, {
    onSuccess: () => {
      data.invalidateKeys.map((key) => queryClient.invalidateQueries(key))
      queryClient.invalidateQueries(GET_TIMELINE_ACTIVITIES)
    },
    onError: (err) => {
      data?.errCb && data?.errCb()
      // errorHandler(err)
    },
  })
}

//create connection
export const useCreateConnection = (data: {
  invalidateKey: any[]
  cb?: () => void
}) => {
  const queryClient = useQueryClient()

  return useMutation(CreateConnection, {
    onSuccess: () => {
      queryClient.invalidateQueries(data.invalidateKey)
      ToastNotify('success', {
        message: 'Success',
      })
      data?.cb && data?.cb()
    },
    onError: (err) => {
      errorHandler(err)
    },
  })
}

//create post media
export const useCreatePostMedia = () => {
  const queryClient = useQueryClient()
  return useMutation(CreatePostMedia, {
    onSuccess: () => {
      queryClient.invalidateQueries([POSTS])
    },
    onError: (err: any) => {
      // errorHandler(err)
    },
  })
}
//delete post
export const useDeletePost = () => {
  return useMutation(DeletePost)
}
//create post
export const useCreatePost = (data: {
  closeModal: () => void
  filesToUpload?: FileToUpload[]
}) => {
  return useMutation(CreatePost)
}
//delete connection
export const useDeleteConnection = (data: { invalidateKey: any[] }) => {
  const queryClient = useQueryClient()

  return useMutation(DeleteConnection, {
    onSuccess: () => {
      queryClient.invalidateQueries(data.invalidateKey)
      ToastNotify('success', {
        message: 'Success',
      })
    },
    onError: (err) => {
      errorHandler(err)
    },
  })
}

//search timeline
export const useSearchTimeline = (data: { search: string }) => {
  return useQuery([TIMELINE_SEARCH, data.search], () => SearchTimeline(data), {
    select: (res: any) => {
      return res.data.data
    },
    enabled: !!data?.search,
  })
}

//get trending startups
export const useGetTrendingStartups = () => {
  return useQuery(TRENDING_STARTUPS, GetTrendingStartups, {
    select: (res: any) => {
      return res.data.data
    },
  })
}
//get timeline mentions
export const useGetTimelineMentions = (data: { query: string }) => {
  return useQuery([POST_MENTIONS, data.query], () => GetMentions(data), {})
}
//get recomended investors
export const useGetRecommendedInvestors = () => {
  return useQuery(RECOMMENDED_INVESTORS, GetRecomendedInvestors, {
    select: (res: any) => {
      return res.data.data
    },
  })
}

//get user
export const useGetUser = (data: { id: number }) => {
  return useQuery([USER, data.id], () => GetUser(data), {
    enabled: !isNaN(Number(data?.id)),
    select: (res: any) => {
      return res.data.data
    },
  })
}
//get post
export const useGetPost = (data: { id: number }) => {
  return useQuery([POST, data.id], () => GetPost(data), {
    enabled: !isNaN(Number(data?.id)),
    select: (res: any) => {
      return res.data.data
    },
  })
}

//get startup
export const useGetStartup = (data: { id: number }) => {
  return useQuery([STARTUP, data.id], () => GetStartup(data), {
    enabled: !isNaN(Number(data?.id)),
    select: (res: any) => {
      return res.data.data
    },
  })
}
//get startup highlights
export const useGetStartupHighlights = (data: { id: number }) => {
  return useQuery(
    [STARTUP_HIGHLIGHTS, data.id],
    () => GetStartupHighlights(data),
    {
      enabled: !isNaN(Number(data?.id)),
      select: (res: any) => {
        return res.data.data
      },
    }
  )
}

//get comments
export const useGetComments = (
  data: { postId: number },
  options?:
    | Omit<
        UseInfiniteQueryOptions<unknown, unknown, unknown, unknown, QueryKey>,
        'queryKey'
      >
    | undefined
) => {
  return useInfiniteQuery(
    [COMMENTS, data.postId],
    ({ pageParam = 1 }) =>
      GetComments({ page: pageParam, postId: data.postId }),
    {
      ...((options ? options : {}) as any),
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(
            lastPage?.data?.data?.next || ''
          ).searchParams?.get('page')

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}
//get posts
export const useGetPosts = (
  data?: {
    startup_id?: number
    period?: number
  },
  options?:
    | Omit<
        UseInfiniteQueryOptions<unknown, unknown, unknown, unknown, QueryKey>,
        'queryKey'
      >
    | undefined
) => {
  return useInfiniteQuery(
    [POSTS],
    ({ pageParam = 1 }) => GetPosts({ page: pageParam, ...data }),
    {
      ...((options ? options : {}) as any),
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(
            lastPage?.data?.data?.next || ''
          ).searchParams?.get('page')

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}
//get connections
export const useGetConnections = (
  data?: { status: 'Pending' | 'Accepted' },
  options?:
    | Omit<
        UseInfiniteQueryOptions<unknown, unknown, unknown, unknown, QueryKey>,
        'queryKey'
      >
    | undefined
) => {
  return useInfiniteQuery(
    [CONNECTIONS, data?.status],
    ({ pageParam = 1 }) => GetConnections({ page: pageParam, ...data }),
    {
      ...((options ? options : {}) as any),
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(
            lastPage?.data?.data?.next || ''
          ).searchParams?.get('page')

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}
//get startups
export const useGetStartups = (
  data: { query: string },
  options?:
    | Omit<
        UseInfiniteQueryOptions<unknown, unknown, unknown, unknown, QueryKey>,
        'queryKey'
      >
    | undefined
) => {
  return useInfiniteQuery(
    [CONNECTIONS, data?.query],
    ({ pageParam = 1 }) => GetStartups({ page: pageParam, ...data }),
    {
      ...((options ? options : {}) as any),
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(
            lastPage?.data?.data?.next || ''
          ).searchParams?.get('page')

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//get users
export const useGetUsers = (
  data: { query: string; 'user type': 'Investor' | 'Startup' | '' },
  options?:
    | Omit<
        UseInfiniteQueryOptions<unknown, unknown, unknown, unknown, QueryKey>,
        'queryKey'
      >
    | undefined
) => {
  return useInfiniteQuery(
    [CONNECTIONS, data?.query, data['user type']],
    ({ pageParam = 1 }) => GetUsers({ page: pageParam, ...data }),
    {
      ...((options ? options : {}) as any),
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(
            lastPage?.data?.data?.next || ''
          ).searchParams?.get('page')

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}
//get timeline activities
export const useGetTimelineActivities = (
  data: {
    activity_type: 'all' | 'comment' | 'like' | 'post'
    user_id: number
    period?: number
    query?: string
  },
  options?:
    | Omit<
        UseInfiniteQueryOptions<unknown, unknown, unknown, unknown, QueryKey>,
        'queryKey'
      >
    | undefined
) => {
  return useInfiniteQuery(
    [
      GET_TIMELINE_ACTIVITIES,
      data?.activity_type,
      data.user_id,
      data?.query,
      data?.period,
    ],
    ({ pageParam = 1 }) => GetTimelineActivities({ page: pageParam, ...data }),
    {
      ...((options ? options : {}) as any),
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(
            lastPage?.data?.data?.next || ''
          ).searchParams?.get('page')

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//get investors and group
export const useGetInvestorGroups = (data: {
  query: string
  category: 'Group' | 'Individual' | 'Network' | 'Syndicate' | 'Fund'
}) => {
  return useInfiniteQuery(
    [
      GET_INVESTORS_GROUP,
      {
        category: data?.category,
        query: data?.query,
      },
    ],
    ({ pageParam = 1 }) => GetInvestorsGroup({ page: pageParam, ...data }),
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

//get group details
export const useGetGroupDetails = (data: { id: number }) => {
  return useQuery(
    [
      GET_GROUPS,
      {
        id: data.id,
      },
    ],
    () => getGroupDetails({ ...data })
  )
}
//Join syndicate

export const useJoinUserGroup = () => {
  return useMutation(JoinUserGroup)
}
//delete user membership

export const useDeleteUserMembership = () => {
  return useMutation(DeleteUserMembership)
}
