export const getPercentColor = (percentage: number) => {
  if (percentage > 0.7) {
    return '#008F5D'
  } else if (percentage > 0.5) {
    return '#EBC345'
  } else return '#F68511'
}

export const getInitials = (name: string) => {
  const [firstName, lastName] = name.split(/\s/)
  return ((firstName?.[0] ?? '') + (lastName?.[0] ?? '')).toUpperCase()
}
