import { InvestorStructure } from '@/apis/networkApis'
import { imagesUrl } from '@/assets/images/imageUrls'
import Button from '@/common/button/Button'
import ConnectionBtn from '@/common/ConnectionBtn'
import Typography from '@/common/Typography'
import { CONNECTIONS } from '@/constants/querryKeys'
import { truncateText } from '@/helpers/ellipsizeTextBox'
import {
  useChangeConnectionStatus,
  useCreateConnection,
  useDeleteConnection,
} from '@/store/networkStore'
import React from 'react'
import { useNavigate } from 'react-router'

function PeopleCard({
  avatar,
  first_name,
  last_name,
  user_type,
  address,
  num_connections,
  connection,
  id,
  startups,
}: Partial<InvestorStructure>) {
  const navigate = useNavigate()
  return (
    <section className='flex flex-col gap-2 bg-white rounded-md p-4'>
      <div className='flex gap-2  md:items-center '>
        <img
          className={
            'rounded-full w-[114.82px] h-[114.82px] object-bottom object-cover'
          }
          alt={'profile_pix'}
          src={avatar || imagesUrl.blanckProfileImg}
        />
        <div className='flex flex-col gap-2 md:flex-row md:w-full md:justify-between md:items-center'>
          <hgroup className='flex flex-col gap-3 md:gap-1'>
            <span
              className='flex flex-col gap-1 cursor-pointer'
              onClick={() =>
                navigate(`/app/networks/user/${first_name} ${last_name}`, {
                  state: {
                    id,
                    from: '/app/networks/startups',
                    fromName: 'Businesses',
                    excludeCount: 2,
                  },
                })
              }
            >
              <Typography heading='3xs'>{`${first_name} ${last_name}`}</Typography>
              <Typography paragraph='xs'>{user_type}</Typography>
            </span>
            <span className='flex gap-1 items-center'>
              <svg
                width='17'
                height='17'
                viewBox='0 0 17 17'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14.5668 6.71032C13.8735 3.62365 11.1801 2.24365 8.82015 2.24365C8.82015 2.24365 8.82015 2.24365 8.81348 2.24365C6.46015 2.24365 3.77348 3.62365 3.07348 6.70365C2.28681 10.1437 4.39348 13.057 6.30015 14.897C7.00681 15.577 7.91348 15.917 8.82015 15.917C9.72681 15.917 10.6335 15.577 11.3335 14.897C13.2401 13.057 15.3468 10.1503 14.5668 6.71032ZM11.0068 7.26365L8.34015 9.93032C8.24015 10.0303 8.11348 10.077 7.98681 10.077C7.86015 10.077 7.73348 10.0303 7.63348 9.93032L6.63348 8.93032C6.44015 8.73699 6.44015 8.41699 6.63348 8.22365C6.82681 8.03032 7.14681 8.03032 7.34015 8.22365L7.98681 8.87032L10.3001 6.55699C10.4935 6.36365 10.8135 6.36365 11.0068 6.55699C11.2001 6.75032 11.2001 7.07032 11.0068 7.26365Z'
                  fill='#464650'
                />
              </svg>
              <Typography paragraph='xs'>
                {truncateText(address!, 4)}
              </Typography>
            </span>
            <Typography paragraph='xs'>{`${num_connections} Connection${
              num_connections! > 1 ? 's' : ''
            }`}</Typography>
          </hgroup>
          {/*  */}
          <hgroup className='flex flex-col gap-2'>
            <Typography paragraph='xs' className='!font-[600]'>
              Associated Startup
            </Typography>
            <span className='flex flex-col gap-2 md:flex-row md:items-center'>
              {startups?.slice(0, 2).map((startup) => (
                <span className='flex items-center gap-2' key={startup.name}>
                  <img
                    src={startup?.logo || imagesUrl.blanckCompImg}
                    alt='start_up_logo'
                    className='h-[21.93px] w-[21.93px] object-center object-cover rounded-[4.57px]'
                  />
                  <Typography paragraph='xs' className='!text-[13.7px]'>
                    {truncateText(startup.name, 2)}
                  </Typography>
                </span>
              ))}
              {startups!?.length > 2 ? (
                <Typography
                  paragraph='xs'
                  className='!text-[13.7px] font-semibold'
                >
                  +{startups!?.length - 2}more
                </Typography>
              ) : null}
            </span>
          </hgroup>

          {/* show on small and lg screens  */}
          <div className='flex items-center gap-2 md:hidden lg:flex'>
            <ConnectionBtn
              createConnectionObj={{
                id: id!,
                type: 'user',
              }}
              invalidateKey={[CONNECTIONS]}
              connection={connection! as any}
              connectionElement={
                <Button color='white' className='rounded' size='sm'>
                  <span className='flex items-center justify-center gap-2'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M11 5.40967V7.40967V5.40967ZM11 7.40967V9.40967V7.40967ZM11 7.40967H13H11ZM11 7.40967H9H11ZM7.66667 4.07633C7.66667 4.78358 7.38572 5.46186 6.88562 5.96195C6.38552 6.46205 5.70724 6.743 5 6.743C4.29276 6.743 3.61448 6.46205 3.11438 5.96195C2.61428 5.46186 2.33333 4.78358 2.33333 4.07633C2.33333 3.36909 2.61428 2.69081 3.11438 2.19072C3.61448 1.69062 4.29276 1.40967 5 1.40967C5.70724 1.40967 6.38552 1.69062 6.88562 2.19072C7.38572 2.69081 7.66667 3.36909 7.66667 4.07633ZM1 12.743C1 11.6821 1.42143 10.6647 2.17157 9.91457C2.92172 9.16443 3.93913 8.743 5 8.743C6.06087 8.743 7.07828 9.16443 7.82843 9.91457C8.57857 10.6647 9 11.6821 9 12.743V13.4097H1V12.743Z'
                        fill='#000014'
                        stroke='#111928'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    Connect
                  </span>
                </Button>
              }
            />

            <Button
              className='rounded px-6 border w-fit'
              size='xs'
              color='white'
            >
              <span className='flex gap-2 items-center justify-center'>
                <svg
                  width='16'
                  height='17'
                  viewBox='0 0 16 17'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.60156 5.11706L7.99916 8.31546L14.3968 5.11706C14.3731 4.70942 14.1944 4.32625 13.8974 4.04604C13.6004 3.76584 13.2075 3.60979 12.7992 3.60986H3.19916C2.79083 3.60979 2.39792 3.76584 2.1009 4.04604C1.80389 4.32625 1.62525 4.70942 1.60156 5.11706Z'
                    fill='#000014'
                  />
                  <path
                    d='M14.3996 6.9043L7.99961 10.1043L1.59961 6.9043V11.6099C1.59961 12.0342 1.76818 12.4412 2.06824 12.7413C2.3683 13.0413 2.77526 13.2099 3.19961 13.2099H12.7996C13.224 13.2099 13.6309 13.0413 13.931 12.7413C14.231 12.4412 14.3996 12.0342 14.3996 11.6099V6.9043Z'
                    fill='#111928'
                  />
                </svg>
                Message
              </span>
            </Button>
          </div>
        </div>
      </div>
      {/* show only on large screens */}
      <div className='hidden md:flex items-center gap-2 self-end lg:hidden'>
        <ConnectionBtn
          createConnectionObj={{
            id: id!,
            type: 'user',
          }}
          invalidateKey={[CONNECTIONS]}
          connection={connection! as any}
          connectionElement={
            <Button color='white' className='rounded' size='sm'>
              <span className='flex items-center justify-center gap-2'>
                <svg
                  width='14'
                  height='14'
                  viewBox='0 0 14 14'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11 5.40967V7.40967V5.40967ZM11 7.40967V9.40967V7.40967ZM11 7.40967H13H11ZM11 7.40967H9H11ZM7.66667 4.07633C7.66667 4.78358 7.38572 5.46186 6.88562 5.96195C6.38552 6.46205 5.70724 6.743 5 6.743C4.29276 6.743 3.61448 6.46205 3.11438 5.96195C2.61428 5.46186 2.33333 4.78358 2.33333 4.07633C2.33333 3.36909 2.61428 2.69081 3.11438 2.19072C3.61448 1.69062 4.29276 1.40967 5 1.40967C5.70724 1.40967 6.38552 1.69062 6.88562 2.19072C7.38572 2.69081 7.66667 3.36909 7.66667 4.07633ZM1 12.743C1 11.6821 1.42143 10.6647 2.17157 9.91457C2.92172 9.16443 3.93913 8.743 5 8.743C6.06087 8.743 7.07828 9.16443 7.82843 9.91457C8.57857 10.6647 9 11.6821 9 12.743V13.4097H1V12.743Z'
                    fill='#000014'
                    stroke='#111928'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                Connect
              </span>
            </Button>
          }
        />

        <Button className='rounded px-6 border w-fit' size='xs' color='white'>
          <span className='flex gap-2 items-center justify-center'>
            <svg
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.60156 5.11706L7.99916 8.31546L14.3968 5.11706C14.3731 4.70942 14.1944 4.32625 13.8974 4.04604C13.6004 3.76584 13.2075 3.60979 12.7992 3.60986H3.19916C2.79083 3.60979 2.39792 3.76584 2.1009 4.04604C1.80389 4.32625 1.62525 4.70942 1.60156 5.11706Z'
                fill='#000014'
              />
              <path
                d='M14.3996 6.9043L7.99961 10.1043L1.59961 6.9043V11.6099C1.59961 12.0342 1.76818 12.4412 2.06824 12.7413C2.3683 13.0413 2.77526 13.2099 3.19961 13.2099H12.7996C13.224 13.2099 13.6309 13.0413 13.931 12.7413C14.231 12.4412 14.3996 12.0342 14.3996 11.6099V6.9043Z'
                fill='#111928'
              />
            </svg>
            Message
          </span>
        </Button>
      </div>
    </section>
  )
}

export default PeopleCard
