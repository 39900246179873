import React, { memo, useMemo } from 'react'
import PosterDetails from './PosterDetails'
import {
  FaCircle,
  FaHeart,
  FaPlayCircle,
  FaPlus,
  FaRegHeart,
} from 'react-icons/fa'
import Button from '@/common/button/Button'
import classNames from 'classnames'
import { imagesUrl } from '@/assets/images/imageUrls'
import moment from 'moment'

import SinglePost from './SinglePost/SinglePost'
import { ReactComponent as ShareIcon } from '@/assets/network/share.svg'
import { ReactComponent as CommentIcon } from '@/assets/network/green comment.svg'
import Typography from '@/common/Typography'
import TextInput from '@/common/TextInput'

import { POST, POSTS } from '@/constants/querryKeys'

import { numberFormatter } from '@/helpers/numberFormatter'
import { ReactComponent as ShareIconHighlighted } from '@/assets/network/share-highlighted.svg'

import { usePostComment, useToggleLike } from '@/store/networkStore'

import { copyToClipBoard } from './utils/copyToClipBoard'
import { SIFUSE_FRONTEND_URL } from '@/apis/endpoints'
import Modal1, { RefType } from '@/common/Modal'

import { TimelineResultsStructure } from '@/apis/networkApis'
import { isImg } from '@/helpers/isImg'
import { formatUserDetails } from '../../../helpers/formatUserDetails'

interface Props {
  containerClass?: string
  hideBtns?: boolean
}
type VideoThumbnailProps = {
  videoUrl: string
  thumbnail: string
  className: string
}

const twoImgClass = 'container m-auto grid grid-cols-2 gap-1'
const threeImgClass = 'container m-auto grid grid-cols-3 gap-1'

function VideoThumbnail({
  videoUrl,
  className,
  thumbnail,
}: VideoThumbnailProps) {
  return (
    <span className={classNames(className, 'relative')}>
      <video
        src={videoUrl}
        className={className}
        poster={thumbnail || imagesUrl.blanckCompImg}
      />
      <FaPlayCircle
        className='absolute left-0 top-0 right-0 bottom-0 m-auto  cursor-pointer text-gray-300'
        size={'50px'}
      />
    </span>
  )
}

function Post({
  user,
  created,
  media,
  content = '',
  id,
  like,
  containerClass,
  num_likes,
  num_comments,
  hideBtns = false,
}: TimelineResultsStructure & Props) {
  const modalRef = React.useRef<RefType>(null)
  const modalRef1 = React.useRef<RefType>(null)

  const [isTextCopied, setisTextCopied] = React.useState<boolean>(false)
  const [isLiked, setisLiked] = React.useState<boolean>(false)

  const [comment, setcomment] = React.useState<string>('')

  const toggleModal1 = (): void => {
    modalRef1?.current?.handleToggle()
  }

  //comment on post
  const { mutate: postComment, isLoading: commentLoading } = usePostComment({
    invalidateKeys: [[POSTS]],
    cb: () => toggleModal1(),
  })

  //toggle like
  const { mutate: likePost, isLoading: likeLoading } = useToggleLike({
    invalidateKeys: [[POST, id], [POSTS]],
    errCb() {
      setisLiked(false)
    },
  })

  useMemo(() => setisLiked(like), [like])

  const toggleModal = (): void => {
    modalRef?.current?.handleToggle()
  }

  return (
    <>
      <div
        className={classNames(
          'bg-[white] flex gap-2 flex-col rounded-md p-[24px] w-full border border-[#E6E6EB]',
          containerClass
        )}
      >
        <div className='flex items-center justify-between'>
          <PosterDetails {...formatUserDetails({ user })} />
        </div>
        <p
          dangerouslySetInnerHTML={{ __html: content }}
          className='cursor-pointer'
          onClick={() => toggleModal()}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        />

        <div
          className={classNames(
            'cursor-pointer',
            { [twoImgClass]: media.length === 2 },
            { [threeImgClass]: media.length >= 3 }
          )}
          onClick={() => toggleModal()}
        >
          {media?.map((img, i, arr) =>
            i <= 2 ? (
              // img tag doesnt support pseudo element, so for the dark overlay, we use a div
              arr.length >= 3 && i === 2 ? (
                <div
                  style={{
                    background: isImg(img.file)
                      ? `url(${img.file}) no-repeat center center/cover`
                      : '',
                  }}
                  className={classNames(
                    //class for 3rd img when img length is 3
                    'bg-gray-200 rounded-br-lg h-[150px] w-[150px] col-span-2 col-start-5  sm:w-[200px] lg:w-[400px]',

                    //class for 3rd img when img length is > 3
                    {
                      [`relative text-[white] flex justify-center items-center`]:
                        arr.length > 3,
                    }
                  )}
                  key={img.id}
                >
                  {arr.length > 3 ? (
                    <span className='absolute left-0 w-full h-full rounded-br-lg text-[white] bg-gray-900/60 z-30 flex items-center justify-center'>
                      <span className='flex items-center gap-1 z-30 text-[25px] '>
                        <FaPlus />
                        {arr.length - 3}
                      </span>
                    </span>
                  ) : null}

                  {!isImg(img.file) && (
                    <VideoThumbnail
                      key={img.id}
                      videoUrl={img.file}
                      thumbnail={img?.metadata?.thumbnail}
                      className='object-center object-cover w-full h-full  rounded-br-lg'
                    />
                  )}
                </div>
              ) : !isImg(img.file) ? (
                <VideoThumbnail
                  key={img.id}
                  videoUrl={img.file}
                  thumbnail={img?.metadata?.thumbnail}
                  className={classNames(
                    ' object-center object-cover',
                    //class for when img length is 1
                    { 'rounded h-[250px] w-[100%]': arr.length === 1 },

                    //class for 1st img when img length is 2
                    {
                      'rounded-l-lg h-[300px] w-full':
                        arr.length === 2 && i === 0,
                    },
                    //class for 2nd img when img length is 2
                    {
                      'rounded-r-lg h-[300px] w-full':
                        arr.length === 2 && i === 1,
                    },
                    //class for 1st img when img length is 3
                    {
                      'rounded-l-lg h-[303px] w-[300px] col-span-4 row-start-1 row-end-3 sm:w-[500px]':
                        arr.length >= 3 && i === 0,
                    },
                    //class for 2nd img when img length is 3
                    {
                      'rounded-tr-lg h-[150px] w-[150px] col-span-2 col-start-5 sm:w-[200px] lg:w-[400px]':
                        arr.length >= 3 && i === 1,
                    }
                  )}
                />
              ) : (
                <img
                  key={img.id}
                  alt='post-img'
                  src={img.file}
                  style={{
                    display: 'block',
                    backgroundImage: `url(${img.file})`,
                  }}
                  className={classNames(
                    ' object-center object-cover',
                    //class for when img length is 1
                    { 'rounded h-[344.95px] w-full': arr.length === 1 },

                    //class for 1st img when img length is 2
                    {
                      'rounded-l-lg h-[300px] w-full':
                        arr.length === 2 && i === 0,
                    },
                    //class for 2nd img when img length is 2
                    {
                      'rounded-r-lg h-[300px] w-full':
                        arr.length === 2 && i === 1,
                    },
                    //class for 1st img when img length is 3
                    {
                      'rounded-l-lg h-[303px] w-[300px] col-span-4 row-start-1 row-end-3 sm:w-[500px]':
                        arr.length >= 3 && i === 0,
                    },
                    //class for 2nd img when img length is 3
                    {
                      'rounded-tr-lg h-[150px] w-[150px] col-span-2 col-start-5 sm:w-[200px] lg:w-[400px]':
                        arr.length >= 3 && i === 1,
                    }
                  )}
                />
              )
            ) : null
          )}
        </div>
        <span className='opacity-50 flex gap-2 items-center'>
          <FaCircle fontSize={6} />
          <p> {moment(created).format('hh:mm A MMMM Do, YYYY')}</p>
        </span>
        {!hideBtns ? (
          <div className='flex justify-between w-full items-center'>
            <div className='flex items-center gap-2'>
              <Button
                className='rounded-md px-2 sm:px-6 border'
                startIcon={
                  isLiked ? (
                    <FaHeart className={isLiked ? 'text-red-500' : ''} />
                  ) : (
                    <FaRegHeart />
                  )
                }
                size='sm'
                color='white'
                onClick={() => {
                  setisLiked((prev) => !prev)
                  likePost({ id })
                }}
              >
                <span
                  className={
                    isLiked
                      ? 'text-red-500 flex items-center justify-center gap-1'
                      : 'flex items-center justify-center gap-1'
                  }
                >
                  {numberFormatter(num_likes)}
                  <span className='hidden sm:block'>
                    {num_likes > 1 ? 'Likes' : 'Like'}
                  </span>
                </span>
              </Button>

              <Button
                className='rounded-md px-2 sm:px-6 border'
                size='sm'
                color='white'
                startIcon={<CommentIcon />}
                onClick={toggleModal1}
              >
                <span className='text-green-600 flex items-center justify-center gap-1'>
                  {' '}
                  {numberFormatter(num_comments)}
                  <span className='hidden sm:block'>
                    {num_comments > 1 ? 'Comments' : 'Comment'}
                  </span>
                </span>
              </Button>

              <Button
                className={'border-none'}
                size='sm'
                color='white'
                startIcon={
                  isTextCopied ? <ShareIconHighlighted /> : <ShareIcon />
                }
                onClick={() =>
                  copyToClipBoard(
                    `${SIFUSE_FRONTEND_URL}/app/networks?post=${id}`,
                    () => setisTextCopied(true)
                  )
                }
              >
                {!isTextCopied ? (
                  <span className='font-medium'> Copy link</span>
                ) : (
                  <span className='text-[#F68511] font-medium'>
                    Link copied
                  </span>
                )}
              </Button>
            </div>
            {/* <FaEllipsisH /> */}
          </div>
        ) : null}
      </div>

      <Modal1
        ref={modalRef}
        modalClass='w-[90vw] sm:w-[800px] min-h-[80vh] pt-2'
      >
        <SinglePost id={id} />
      </Modal1>
      <Modal1
        ref={modalRef1}
        modalClass='w-[80vw] sm:!w-[50vw] lg:!w-[40vw] !min-h-[20vh]'
        enableWarning
        showCloseBtn
      >
        <div className='flex flex-col gap-2 items-start w-full p-2'>
          <Typography heading='3xs'>Comment on this post</Typography>
          <TextInput
            onChange={(e) => setcomment(e.target.value)}
            value={comment}
            fieldType='textarea'
            rows='5'
            placeholder='Write comment...'
            className='w-full'
            containerClass='!w-full'
          />
          <Button
            onClick={() => postComment({ id, content: comment })}
            loading={commentLoading}
            className='self-end'
            size='sm'
          >
            Post comment
          </Button>
        </div>
      </Modal1>
    </>
  )
}

export default memo(Post)
