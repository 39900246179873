import Button from '@/common/button/Button'
import React from 'react'

import { useLocation, useParams } from 'react-router'

import { FaEnvelope } from 'react-icons/fa'

import StartupHighlights from './components/StartupHighlights'
import Typography from '@/common/Typography'

import LoadingState from './components/LoadingState'
import { imagesUrl } from '@/assets/images/imageUrls'

import { useGetTeamMembers } from '@/store/teamStore'

import { TEAM_MEMBER } from '@/apis/endpoints'
import Title from '@/common/Title'
import { useBreadCrumbs } from '@/layouts/app/components/header'
import { useMessageRecipient } from '../message/hooks'
import ConnectionBtn from '../../common/ConnectionBtn'

interface Members {
  id: number
  first_name: string
  last_name: string
  avatar: string
  about: string
  num_connections: number
  startup_logo: string
  startup_name: string
  connection: null | {
    id: number
    status: 'Pending' | 'Accepted' | 'Declined'
    is_sender: boolean
  }
}

const Desc = ({
  id,
  first_name = '',
  last_name = '',
  avatar,
  about,
  num_connections,
  connection,
  startup_name,
  startup_logo,
}: Members) => {
  const { id: sid } = useParams()
  const startupId = Number(sid)
  const messageUser = useMessageRecipient()

  return (
    <>
      <div className='border border-gray-200 my-2 p-2  rounded-lg flex flex-col gap-2 items-start w-full lg:flex-row sm:justify-between sm:items-start sm:p-4'>
        <div className='flex gap-2 flex-col justify-center sm:flex-row'>
          <img
            src={avatar || imagesUrl.blanckProfileImg}
            alt='start_up_logo'
            className='h-[114px] w-[114px] object-center object-cover rounded-full'
          />
          <div className='flex flex-col gap-1 px-2 sm:px-3'>
            <div className='flex flex-col items:start justify-center'>
              <Typography heading='3xs'>{`${first_name} ${last_name}`}</Typography>

              <p>{about}</p>

              <div className='flex items-center gap-2 w-full'>
                <span className='flex gap-1'>
                  <small className='font-medium'> {num_connections}</small>
                  <small>Connection{num_connections > 1 ? 's' : null}</small>
                </span>
              </div>
            </div>
            <div className='flex gap-2 mt-2'>
              <Typography heading='3xs'>Associated Business</Typography>

              <div className='flex items-center justify-center gap-1'>
                <img
                  src={startup_logo || imagesUrl.blanckProfileImg}
                  alt='start_up_logo'
                  className='h-[20px] w-[20px] object-center object-cover rounded-full'
                />
                <small>{startup_name}</small>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-row gap-2 align-start'>
          <ConnectionBtn
            createConnectionObj={{
              id,
              type: 'startup',
            }}
            invalidateKey={[TEAM_MEMBER, startupId]}
            connection={connection as any}
          />
          <Button
            className='rounded px-2 sm:px-4 border'
            color='white'
            startIcon={<FaEnvelope />}
            size='sm'
            onClick={() => messageUser(startupId)}
          >
            Message
          </Button>
        </div>
      </div>
    </>
  )
}
function TeamInfo() {
  const { startupName } = useParams()
  const { state } = useLocation()
  const startupId = Number(state?.id)

  const BreadCrumbMap = new Map()
  BreadCrumbMap.set('/app/networks', 'The Network')
  BreadCrumbMap.set('/app/networks/startups', 'Businesses')
  BreadCrumbMap.set(`/app/networks/startups/${startupName}`, startupName)
  BreadCrumbMap.set(
    `/app/networks/startups/${startupName}/teaminfo`,
    `Team info`
  )
  // BreadCrumbMap.set('excludeCount', 1)
  useBreadCrumbs(BreadCrumbMap)

  //get startup

  const {
    data: members,
    isLoading: membersLoading,
    isError: ismembersError,
  } = useGetTeamMembers({ startup: startupId ? startupId : 0 })

  const membersData = members as unknown as Members[]
  const isMembersEmpty = membersData?.length < 1

  return (
    <div className='flex flex-col items-start p-6 gap-4 sm:flex-row '>
      <Title>{`Team info`}</Title>
      <div className='flex flex-col w-full p-2 gap-2 bg-white rounded-md sm:p-4 sm:w-[70%]'>
        <Typography heading='2xs'>Team Info</Typography>
        {
          <LoadingState
            condition={
              membersLoading
                ? 'isLoading'
                : isMembersEmpty
                ? 'isEmpty'
                : ismembersError
                ? 'isError'
                : ''
            }
            isEmptyDisplay={
              <>
                <Typography heading='3xs'>No data</Typography>
              </>
            }
          />
        }
        {membersData?.map((item) => (
          <Desc key={item.id} {...item} />
        ))}
      </div>

      <div className='flex w-full flex-col gap-5 sm:w-[30%]'>
        <StartupHighlights />
      </div>
    </div>
  )
}

export default TeamInfo
