import { imagesUrl } from '@/assets/images/imageUrls'
import Button from '@/common/button/Button'

import { FaEnvelope } from 'react-icons/fa'
import { useNavigate } from 'react-router'

import Typography from '@/common/Typography'
import { ReactComponent as ConnectedIcon } from '@/assets/network/connected.svg'
import { useDeleteConnection } from '@/store/networkStore'
import { CONNECTIONS } from '@/constants/querryKeys'
import { useMessageRecipient } from '@/pages/message/hooks'

import { Receiver, Sender } from '@/apis/networkApis'

import clsx from 'clsx'

function FollowingItem({
  item,
  connectionId,
}: {
  item: Receiver | Sender
  connectionId: number | string
}) {
  const navigate = useNavigate()
  const messageUser = useMessageRecipient()

  // delete connection
  const { mutate: deleteConnection, isLoading: deleteConnectionLoading } =
    useDeleteConnection({
      invalidateKey: [CONNECTIONS],
    })

  return (
    <div className='flex flex-col gap-3 w-full'>
      <div className='flex flex-col gap-2 items-start w-full lg:flex-row lg:justify-between lg:items-center'>
        <div className='flex gap-2'>
          <img
            src={
              item?.img || item.type === 'Individual'
                ? imagesUrl.blanckProfileImg
                : imagesUrl.blanckCompImg
            }
            alt='start_up_logo'
            className={clsx(
              'h-[80px] w-[80px] min-h-[80px] min-w-[80px] object-center object-cover rounded-full',
              {
                '!rounded-md': item.type !== 'Individual',
              }
            )}
          />
          <div className='flex flex-col gap-1 px-2 sm:px-3'>
            <div
              className='flex flex-col items:start justify-center cursor-pointer'
              onClick={() =>
                navigate(
                  item.type === 'Business'
                    ? `/app/networks/startups/${item.name}`
                    : item.type === 'Group'
                    ? `/app/networks/group/${item.name}`
                    : item.type === 'Entity'
                    ? `/app/networks/funds/${item.name}`
                    : `/app/networks/user/${item.name}`,
                  {
                    state: {
                      id: item.id,
                      fromName: 'My Network',
                      from: '/app/networks/mynetwork',
                      excludeCount: 2,
                    },
                  }
                )
              }
            >
              <Typography heading='3xs'>{item.name}</Typography>

              {/* <small className={`font-light`}>
                {' '}
                {truncateText(
                item.type,
                  10
                )}
              </small> */}
            </div>
            <Typography paragraph='xs' className='!font-medium'>
              {item.type}
            </Typography>

            <div className='flex items-center gap-2 justify-between w-full'>
              <span className='flex gap-2'>
                <small>
                  {' '}
                  <strong>{item?.num_connections}</strong>
                </small>
                <small>
                  Connection{item?.num_connections > 1 ? 's' : null}
                </small>
              </span>
            </div>
          </div>
        </div>

        <div className='flex gap-2 mt-0 sm:gap-4'>
          <Button
            color='lightgreen'
            className='!border !border-gray-200 rounded px-4'
            onClick={() => deleteConnection({ id: +connectionId })}
            startIcon={<ConnectedIcon />}
            size='sm'
            loading={deleteConnectionLoading}
          >
            Unconnect
          </Button>

          <Button
            className='rounded px-4 border '
            startIcon={<FaEnvelope />}
            size='md'
            color='white'
            onClick={() =>
              messageUser(
                item.id
                // investor_group ? 'group' : 'user'
              )
            }
          >
            message
          </Button>
        </div>
      </div>
      <hr className='bg-gray-200' />
    </div>
  )
}

export default FollowingItem
