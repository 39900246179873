import { PaginatedData, PaginationReq } from '@/apis/api.types'
import clsx from 'clsx'
import {
  CSSProperties,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react'
import { useInView } from 'react-hook-inview'
import { UseInfiniteQueryResult } from 'react-query'
import { BeatLoader } from 'react-spinners'

type Props<T extends object> = {
  getInfiniteData: (
    data: PaginationReq<T>
  ) => UseInfiniteQueryResult<PaginatedData<unknown>>
  data: PaginationReq<T>
  children: ReactNode | Function
  className?: string
  style?: CSSProperties
}
export type InfiniteScrollResult<T = any> = {
  isInitialLoad: boolean
  allPageData: T[]
}
const InfiniteScroll = forwardRef<InfiniteScrollResult, Props<any>>(
  (props, forwardedRef) => {
    const { getInfiniteData, data, children, className, style } = props
    const {
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isLoading,
      data: pagesPayload,
    } = getInfiniteData(data)
    const allData = useMemo(
      () =>
        pagesPayload?.pages
          ? pagesPayload?.pages.flatMap((res) =>
              Array.isArray(res?.results) ? res.results : []
            )
          : [],
      [pagesPayload]
    )

    const [loadMoreRef, inView] = useInView()
    useEffect(() => {
      if (!inView) return
      fetchNextPage()
    }, [inView, fetchNextPage])
    useImperativeHandle(
      forwardedRef,
      () => ({
        allPageData: allData,
        isInitialLoad: isLoading,
      }),
      [allData, isLoading]
    )
    return (
      <div style={style ? style : {}} className={clsx('w-full', className)}>
        {typeof children === 'function'
          ? children({ allPageData: allData, isInitialLoad: isLoading })
          : children}
        {hasNextPage && <div id='top' ref={loadMoreRef}></div>}
        {isFetchingNextPage && (
          <BeatLoader color='var(--mainprimary)' size={20} />
        )}
      </div>
    )
  }
)
InfiniteScroll.displayName = 'InfiniteScroll'

export default InfiniteScroll
