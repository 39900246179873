import { PaginationReq } from '@/apis/api.types'
import { GROUP_MEMBERS, STARTUPS, TEAM_MEMBER } from '@/apis/endpoints'
import {
  ActivateOrDeactivateMember,
  GroupMembers,
  InviteMember,
  ModifyFounder,
  TeamMembers,
} from '@/apis/teamApis'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

export const useGetTeamMembers = (data: PaginationReq) => {
  return useInfiniteQuery(
    [TEAM_MEMBER, data],
    ({ pageParam = 1 }) => {
      return TeamMembers({ ...data, page: pageParam })
    },
    {
      getNextPageParam: (lastPage: any) => {
        try {
          const nextUrlParams = new URL(lastPage?.next || '').searchParams?.get(
            'page'
          )

          return nextUrlParams
        } catch (error) {
          return
        }
      },
    }
  )
}

export const useInviteMember = () => {
  return useMutation(InviteMember)
}

export const useModifyFounder = () => {
  const queryClient = useQueryClient()
  return useMutation(ModifyFounder, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(TEAM_MEMBER)
      queryClient.invalidateQueries(STARTUPS)
      queryClient.invalidateQueries([STARTUPS, variables.startup])
    },
  })
}

export const useActivateOrDeactivateMember = () => {
  const queryClient = useQueryClient()
  return useMutation(ActivateOrDeactivateMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(TEAM_MEMBER)
      queryClient.invalidateQueries(STARTUPS)
    },
  })
}
export const useGetGroupMembers = (id: number) => {
  return useQuery([GROUP_MEMBERS, id], () => GroupMembers({ id }))
}
